import React, {Component} from "react";
import { Route, Switch } from "react-router-dom";
import MenuLateral from "./components/MenuLateral";
import MenuTop from "./components/MenuTop";
import pageDashboard from "./containers/pageDashboard";
import pageListUsers from "./containers/pageListUsers";

import pageListOrdersPending from "./containers/pageListOrdersPending";
import pageListProducts from "./containers/pageListProducts";
import pageListProductsSN from "./containers/pageListProductsSN";
import pageListStock from "./containers/pageListStock";
import pageUser from "./containers/pageUser";
import pageProduct from "./containers/pageProduct";
// import pageStock from "./containers/pageStock";
import pageListProductStock from "./containers/pageListProductStock";
import pageListProductStockSold from "./containers/pageListProductStockSold";
import pageAddStock from "./containers/pageAddStock";
import pageRemoveStock from "./containers/pageRemoveStock";
import pageRemoveStockOrder from "./containers/pageRemoveStockOrder";
import pageStockItem from "./containers/pageStockItem";
import pageOrder from "./containers/pageOrder";

import pageListOrdersRegistered from "./containers/pageListOrdersRegistered";
import pageListOrderRegistered from "./containers/pageListOrderRegistered";
import pageListOrdersEntry from "./containers/pageListOrdersEntry";
import pageListOrderEntry from "./containers/pageListOrderEntry";

import Request, {CODES, isAdministrator, isGestorTienda} from '../utils/Request';
// import { Auth } from '../utils/Auth';
import UserContext from '../utils/UserContext';

// import pageUnit from "./containers/pageUnit";
// import pageListEnrollments from "./containers/pageListEnrollments";
// import pageEnrollment from "./containers/pageEnrollment";
// import Gallery from './components/Gallery';

// import './css/style.css';



class CmsApp extends Component {
	constructor(props){
		super(props);
		this.state = {
			currentUser : {},
		}
	}

	componentDidMount(){
		try {  
			document.createEvent("TouchEvent");  
			document.querySelector("body").classList.add("touch-device");
		} catch (e) {  
			document.querySelector("body").classList.remove("touch-device");
		}

		// const token = Auth.getToken();
		// console.log("token ", token);

		Request.getAuth('/api/account/verify').then( res => {
			if(res.code === CODES.success){
				this.setState({currentUser: res.user})
			}else{
				// console.log("need login");
			}

			if(!isAdministrator(res.user.role) && !isGestorTienda(res.user.role)){
				this.props.history.push('/');
			}
		});


	}
	
	
	render(){
		if(!isAdministrator(this.state.currentUser.role) && !isGestorTienda(this.state.currentUser.role)){
			return null;
		}

		return(

			<UserContext.Provider value={this.state.currentUser}>
				<div className="cms-app">
					<MenuTop/>
					<MenuLateral/>
	 				<Switch>
						{/*<Route path="/admin/deleted-users" component={pageListDeletedUsers} />*/}
						
						<Route path="/usuarios/:userId" component={pageUser} />
						<Route path="/usuarios" component={pageListUsers} />

						<Route path="/productos/:productId" component={pageProduct} />
						<Route path="/productos" component={pageListProducts} />

						<Route path="/productos-sn" component={pageListProductsSN} />

						<Route path="/add-stock/" component={pageAddStock} />
						<Route path="/remove-stock/" component={pageRemoveStock} />
						<Route path="/remove-stock-pedido/:orderId" component={pageRemoveStockOrder} />
						<Route path="/remove-stock-pedido/" component={pageRemoveStock} />

						<Route path="/stock/:productId/vendidos/" component={pageListProductStockSold} />
						<Route path="/stock/:productId/" component={pageListProductStock} />
						<Route path="/stock" component={pageListStock} />

						<Route path="/item/:stockId" component={pageStockItem} />
						
						<Route path="/pedidos/:orderId" component={pageOrder} />
						<Route path="/pedidos" component={pageListOrdersPending} />

						<Route path="/pedidos-registrados/:date/:order" component={pageListOrderRegistered} />
						<Route path="/pedidos-registrados" component={pageListOrdersRegistered} />

						<Route path="/entradas/:entryEncoded" component={pageListOrderEntry} />
						<Route path="/entradas" component={pageListOrdersEntry} />
						
						<Route path="/" component={pageDashboard} />
	  				</Switch>
				</div>
			</UserContext.Provider>
		);
	}
}

export default CmsApp;

