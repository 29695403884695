import React from "react";
import {NavLink} from "react-router-dom";

import Request, {CODES} from '../utils/Request';
import {Auth} from "../utils/Auth";
import Form from "../utils/Form";

import InputText from "../utils/inputs/InputText";
import InputPassword from "../utils/inputs/InputPassword";
import InputTrueFalse from "../utils/inputs/InputTrueFalse";

class FormResetPassword extends Form {

	constructor(props){
		super(props);
		
		this.formRef = React.createRef();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.getFormMessage = this.getFormMessage.bind(this);
	}
	
	handleSubmit(event){
		event.preventDefault();
		if(this.isValidForm()){
			if(!this.sendingForm){
				this.sendingForm = true;

				Request.post('/api/account/reset-password',{
						hash: this.props.hash,
						password: this.state.values.password.trim(),
					})
					.then(json => {
					 	this.sendingForm = false;
						if( json.code === CODES.success){
							Auth.setToken(json.token, json.user);
							this.props.redirectFrom();
						}else {
							this.setState({
								formMessage: {
									text: json.message,
									status: 'error',
								},
							})
						}
				});
			}
		}else{
			console.error("Input error: this.isValidForm() = false");
		}
	}

	render(){

		let statusClass = '';
		if(this.state.formMessage && this.state.formMessage.status !== 'success'){
			statusClass = 'form-error';
		}

		return(
			<div className="login-body bg-color-white">
				<p className="login-body-title font-h4 font-center">Cambia tu contraseña:</p>
				<form onSubmit={this.handleSubmit} className={'bb-form ' + statusClass} ref={this.formRef}>
					<InputPassword placeholder="Contraseña" name="password" form={this} required confirm={true}/>

					<div className="button-container font-center">
						<input className="button button-blue font-p" type="submit" value="Guardar contraseña"/>
					</div>	
					<p className="form-message font-p2 font-white ">Mensaje: {this.state.formMessage.text}&nbsp;</p>

				</form>

			</div>
		);
	}
}

export default FormResetPassword;