import React, {useContext} from "react";

import {NavLink, Link} from "react-router-dom";
import pageList from './pageList';
import Request, {CODES} from '../../utils/Request';

import UserContext from '../../utils/UserContext';

import {RMA} from '../../utils/svgs';


class pageListOrderRegistered extends pageList {
	
	constructor(props){
		super(props)
		this.date = this.props.match.params.date;
		this.order = this.props.match.params.order;

		this.responseField = 'products';
		this.apiName = 'orders-registered/'+this.date+'/'+this.order;
		this.listSlug = 'orders';
		this.singleItem = 'pedido'; //Name of a single list item
		this.listTitle = 'Ficha del pedido: '+this.order;
		this.listSubTitle = 'Fecha: '+this.date;
		// this.hasRowOptions = false;

		this.listWithoutLinks = true;
		// this.itemLinkSlug = 'item';

		this.product = null;
	}
	
	componentDidMount(){
		this.requestPages();
	}

	getTable(pages){
		let table = {};
		table.headers = [
			{
				slug: 'serial_number',
				name: 'Número Serie',
				class: 'item-big',
				canSearch: true,
			},
			{
				slug: 'sku',
				name: 'SKU',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'provider_bought',
				name: 'Proveedor',
				class: 'item-medium',
			},
			{
				slug: 'invoice_bought',
				name: 'Factura Compra',
				class: 'item-medium',
			},
			{
				slug: 'date_bought',
				name: 'Fecha Compra',
				class: 'item-medium',
			},
		];
		table.rows = [];
		for(let i = 0; i < pages.length; i++){
			table.rows.push(this.createRow(pages[i]));
		}
		return table;
	}

	createRow(page){
		return {
			item : page,
			id : page.id,
			serial_number : page.serial_number,
			sku : page.sku,
			date_bought : page.date_bought,
			provider_bought : page.provider_bought,
			invoice_bought : page.invoice_bought,
		};
	}

	addStockAgain(page){
		let product_name = page.sku;
		if(page.serial_number){
			product_name+= ' ('+page.serial_number+')';
		}
		let comment = window.prompt('Escribe un comentario y pulsa aceptar para devolver el producto:\n'+product_name);
		if(comment && comment.trim()){
			if(!this.sendingForm){
				this.sendingForm = true;
				let values = {
					id_product: page.item.id_product,
					provider_bought: page.provider_bought,
					invoice_bought: page.invoice_bought,
					date_bought: page.date_bought,
					serial_number: page.serial_number,
					comment: comment.trim(),
				}
				Request.postAuth('/api/stock-item/'+page.id, values)
					.then(json => {
					 	this.sendingForm = false;
						window.alert('Artículo '+product_name+' devuelto');
					 	window.location.reload();
				});
			}
		}
	}

	renderAddNew(){
		return <Link to={'/remove-stock/?order='+this.order+'&date='+this.date} className="button button-default font-p font-white font-center">Retirar más productos</Link>;
	}



	renderDeleteIcon(row, index){
		return <div className="icon-container font-right" title="Volver a añadir al stock" onClick={()=>this.addStockAgain(row)}>
					<RMA/>
				</div>
	}
}





export default pageListOrderRegistered;




