import React, {Component} from "react";




class InputEmail extends Component {
	constructor(props){
		super(props);

		this.state = {
			status : 'success',
			inputMessage : '',
			value: this.props.form.getValue(this.props.name),
		}

		this.validateInput = this.validateInput.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
	}

	componentDidMount(){
		let value = this.props.form.getValue(this.props.name);
		if((value == "" || value == undefined) && this.props.defaultValue !== undefined){
			this.props.form.setValue(this.props.name, this.props.defaultValue);
			value = this.props.defaultValue;
		}
		this.validateInput(value);
	}

	componentWillUnmount(){
		this.props.form.setValidate(this.props.name, true);
	}


	validateInput(value){
		let re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/;
		let valid = true;

		if(this.props.required && ( value === undefined || value === "")){
			valid = false;
			this.setState({
				status: "error",
				inputMessage: this.props.name + ' is required.',
			})
		}else if(!re.test(value)){
			valid = false;
			this.setState({
				status: "error",
				inputMessage: this.props.name + ' format is invalid.',
			})
		}else{
			this.setState({
				status: "success",
				inputMessage: '',
			})
		}
		this.props.form.setValidate(this.props.name, valid);
	}

	handleOnChange(event){
		this.validateInput(event.target.value);
		this.props.form.setValue(this.props.name, event.target.value);
	}

	render(){
		let statusClass = (this.state.status === "error") ? "input-error" : "";
		let value = this.props.form.getValue(this.props.name) || this.props.defaultValue ;
		let input = 
		    <label className={"font-p input-container "+statusClass} data-message={this.state.inputMessage}>
		    	{(this.props.label !== undefined)? 
		    		(<span className="label-text">{this.props.label + ": "}</span>)
		    		:
		    		(null)
		    	}
	        	<input type="email" name={this.props.name} value={value} onChange={this.handleOnChange} readOnly={this.props.readOnly} placeholder={this.props.placeholder} />
	        </label>
		return input;
	}
}

export default InputEmail;