import React, {Component} from "react";
// import GeneralAnimations from '../utils/generalAnimations';




class pageLogin extends Component {
	
	constructor(props){
		super(props);

		this.from = null;
		
		if(this.props.location.state){
			this.from = this.props.location.state.from.pathname;
		}

		
		
	}

	
	render(){		
		
		return(
			<section className="page page404">
				<p className="font-h1"> page 404 </p>			
			</section>
		);
	}
}





export default pageLogin;








