import React, {Component} from "react";
import { Link, Prompt } from "react-router-dom";
// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";
import Request, {CODES} from '../../utils/Request';
import Form from "../../utils/Form";
import InputText from "../../utils/inputs/InputText";
import InputTrueFalse from "../../utils/inputs/InputTrueFalse";
import InputNumber from "../../utils/inputs/InputNumber";
import InputIdProduct from "../../utils/inputs/InputIdProduct";
import InputDate from "../../utils/inputs/InputDate";
import InputNewSerialNumber from "../../utils/inputs/InputNewSerialNumber";
import InputProvider from "../../utils/inputs/InputProvider";
// import { ArrowFillUp, ArrowFillDown} from '../../utils/svgs';


class pageAddStock extends Form {
	
	constructor(props){
		super(props);
		this.formRef = React.createRef();
		
		this.units = null;
		this.from = null;
		if(this.props.location.state){
			this.from = this.props.location.state.from.pathname;
		}

		// this.handleInput = this.handleInput.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getFormMessage = this.getFormMessage.bind(this);
		this.isStatusSuccess = this.isStatusSuccess.bind(this);
	}
	
	componentDidMount(){
		// /*GeneralAnimations.prepareGeneralAnimations();
		// setTimeout( GeneralAnimations.animateGeneralElements, 400);*/
		// Request.getAuth('/api/stock/'+this.productId).then( res => {
		// 	if(res.code === CODES.success){
		// 		this.setState({
		// 			currentStock:res.stock,
		// 			values: res.stock,
		// 			loaded: true,
		// 		});
		// 	}else{
		// 		// console.log("need login");
		// 	}
		// });

		let stock = {
			provider_bought: '',
			invoice_bought: '',
			date_bought: '',
			products: [{
				id_product: '',
				serial_number: '',
			}],
		};

		let urlParams = new URLSearchParams(this.props.location.search);
		if(urlParams.get('invoice')){
			stock.invoice_bought = urlParams.get('invoice');
		}
		if(urlParams.get('date')){
			stock.date_bought = urlParams.get('date');
		}
		if(urlParams.get('provider')){
			stock.provider_bought = urlParams.get('provider');
		}

		this.setState({
			currentStock: stock,
			values: stock,
			loaded: true,
		});
	}

	handleSubmit(event){
		event.preventDefault();
		if(this.isValidForm()){
			if(!this.sendingForm){
				this.sendingForm = true;
				Request.postAuth('/api/stock/add', this.state.values)
					.then(json => {
					 	this.sendingForm = false;
					 	if(json.code == CODES.success){
					 		// this.productId = json.product.id;
						 	this.setState({
						 		modified: false,
						 		formMessage: {
									status: 'success',
									text: 'Pedido registrado',
								}
						 	})
						 	window.alert('pedido registrado');
							window.location = '/stock';
					 	}else{
						 	this.setState({
						 		formMessage: {
									status: 'error',
									text: json.message,
								}
						 	})
					 	}
				});
			}
		}else{
			console.error("Input error: this.isValidForm() = false");
		}
	}

	isStatusSuccess(){
		if(this.state.formMessage && this.state.formMessage.status !== 'success'){
			return false;
		}
		return true;
	}

	render(){
		if(!this.state.loaded) return null;

		let buttonText = "Registrar pedido";
		let title = "Registrar nuevo pedido entrante";
		
		let statusClass = '';
		if(!this.isStatusSuccess()){
			statusClass = 'form-error';
		}

		// let CurrentStock = () => (null);
		// if(this.state.currentStock){
		// 	CurrentStock = ()=> {
		// 		return print_obj(this.state.currentStock);
		// 	};
		// 	function print_obj(obj){
		// 		return Object.keys(obj).map(key=>(
		// 			<p className="font-p" key={key}> <strong>{key}</strong>: {(typeof obj[key] != 'object')?obj[key] : print_obj(obj[key])}</p>
		// 		))
		// 	}
		// }

		return(
			<section className="page page-course">
				<Prompt when={this.state.modified} message={() => ('¿Seguro que quieres salir sin guardar?')} />
				<div className="page-main-container">
					{/*<CurrentStock/>	<br/>	<br/>*/}
					<div className="page-title">
						<div className="container-left">
							<p className="font-h2">{title}</p>
						</div>
					</div>

					<form onSubmit={this.handleSubmit} className={'bb-form ' + statusClass} ref={this.formRef}>
						<InputProvider label="Proveedor" name="provider_bought" form={this} required />
						<InputText label="Factura" name="invoice_bought" form={this} required />
						<InputDate label="Fecha compra" name="date_bought" form={this} required />
						
						<div className="form-section">
							<p className="section-title font-h3">Productos comprados</p>
							{this.state.values.products.map((product, i) => (
								<div className="section-item" key={'product'+i}>
									<div className="section-item-title">
										<div className="section-title-right">
											<p className="font-p button" onClick={this.deleteItem} name={"products"} index={i}>Eliminar</p>
										</div>
									</div>
									<InputIdProduct label="Producto" name={"products["+i+"].id_product"} form={this} required />
									<InputTrueFalse label="¿Tiene número de serie?" name={"products["+i+"].has_serial"} form={this} defaultValue={true} />
									{(this.state.values.products[i].has_serial)?(
										<InputNewSerialNumber label="Número de series" name={"products["+i+"].serial"} form={this} required />
									):(
										<InputNumber label="Cantidad" name={"products["+i+"].quantity"} form={this} required />
									)}
								</div>
							))}
							<p className="font-p button" onClick={this.addItem} name={"products"}>Añadir producto</p>
						</div>
					
						<div className="button-container font-center">
							<input className="button button-blue font-p" type="submit" value={buttonText}/>
						</div>	
						<p className="form-message font-p2">Form message: {this.state.formMessage.text}&nbsp;</p>
					</form>
				</div>

			</section>
		);
	}
}





export default pageAddStock;




