import React, {Component} from "react";
import { Link, Prompt } from "react-router-dom";
// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";
import Request, {CODES} from '../../utils/Request';
import Form from "../../utils/Form";
import InputText from "../../utils/inputs/InputText";
import InputTrueFalse from "../../utils/inputs/InputTrueFalse";
import InputNumber from "../../utils/inputs/InputNumber";
import InputIdProduct from "../../utils/inputs/InputIdProduct";
import InputDate from "../../utils/inputs/InputDate";
import InputSerialNumber from "../../utils/inputs/InputSerialNumber";
// import { ArrowFillUp, ArrowFillDown} from '../../utils/svgs';


class pageRemoveStockOrder extends Form {
	
	constructor(props){
		super(props);
		this.formRef = React.createRef();
		this.state.products = [];
		
		this.units = null;
		this.from = null;
		if(this.props.location.state){
			this.from = this.props.location.state.from.pathname;
		}

		this.productsNoSerials = {};

		// this.handleInput = this.handleInput.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getFormMessage = this.getFormMessage.bind(this);
		this.isStatusSuccess = this.isStatusSuccess.bind(this);
	}
	
	componentDidMount(){
		// /*GeneralAnimations.prepareGeneralAnimations();
		// setTimeout( GeneralAnimations.animateGeneralElements, 400);*/
		// Request.getAuth('/api/stock/'+this.productId).then( res => {
		// 	if(res.code === CODES.success){
		// 		this.setState({
		// 			currentStock:res.stock,
		// 			values: res.stock,
		// 			loaded: true,
		// 		});
		// 	}else{
		// 		// console.log("need login");
		// 	}
		// });

		let stock = {
			date_sold: '',
			order_sold: '',
			comment: '',
			serials: [],
			productsNoSerials: [/*{
				id_product: '',
				quantity: '',
			}*/],
		};
		let urlParams = new URLSearchParams(this.props.location.search);
		if(urlParams.get('order')){
			stock.order_sold = urlParams.get('order');
		}
		if(urlParams.get('date')){
			stock.date_sold = urlParams.get('date');
		}

		let products = []
		Request.getAuth('/api/products/searcher/?serial=0').then( res => {
			if(res.code === CODES.success){
				let options = [];
				let label = this.state.label;
				for(let product of res.products){
					this.productsNoSerials[product.id] = product.sku;
				}
			}
		});

		this.setState({
			currentStock: stock,
			values: stock,
			loaded: true,
			products: products,
		});
	}

	handleSubmit(event){
		event.preventDefault();
		if(this.isValidForm()){
			if(!this.sendingForm){
				this.sendingForm = true;
				Request.postAuth('/api/stock/remove', this.state.values)
					.then(json => {
					 	this.sendingForm = false;
					 	if(json.code == CODES.success){
					 		// this.productId = json.product.id;
						 	this.setState({
						 		modified: false,
						 		formMessage: {
									status: 'success',
									text: 'Pedido registrado',
								}
						 	})
						 	window.alert('pedido registrado');
							// window.location = '/pedidos-registrados/'+this.state.values.date_sold+'/'+this.state.values.order_sold;
							window.location = '/stock';
					 	}else{
						 	this.setState({
						 		formMessage: {
									status: 'error',
									text: json.message,
								}
						 	})
					 	}
				});
			}
		}else{
			console.error("Input error: this.isValidForm() = false");
		}
	}

	isStatusSuccess(){
		if(this.state.formMessage && this.state.formMessage.status !== 'success'){
			return false;
		}
		return true;
	}

	valueChanged(name, value){
		let serialProducts = {}
		for(let serial of this.state.values.serials){
			if(!serialProducts[serial.sku]){
				serialProducts[serial.sku] = [];
			}
			serialProducts[serial.sku].push(serial.label);
			
		}

		let products = [];
		for(let sku in serialProducts){
			products.push({
				name: sku,
				quantity: serialProducts[sku].length, 
				serials: serialProducts[sku],
			})
		}

		for(let product of this.state.values.productsNoSerials){
			if(product.id_product){
				products.push({
					name: this.productsNoSerials[product.id_product],
					quantity: Number(product.quantity) || 0,
					serials: [],
				})
			}
		}

		this.setState({products: products})
	}

	render(){
		if(!this.state.loaded) return null;

		let buttonText = "Retirar stock";
		let title = "Registrar nuevo pedido saliente";
		
		let statusClass = '';
		if(!this.isStatusSuccess()){
			statusClass = 'form-error';
		}

		// let CurrentStock = () => (null);
		// if(this.state.currentStock){
		// 	CurrentStock = ()=> {
		// 		return print_obj(this.state.currentStock);
		// 	};
		// 	function print_obj(obj){
		// 		return Object.keys(obj).map(key=>(
		// 			<p className="font-p" key={key}> <strong>{key}</strong>: {(typeof obj[key] != 'object')?obj[key] : print_obj(obj[key])}</p>
		// 		))
		// 	}
		// }

		let totalProducts = 0;

		return(
			<section className="page page-course">
				<Prompt when={this.state.modified} message={() => ('¿Seguro que quieres salir sin guardar?')} />
				<div className="page-main-container">					
					{/*<CurrentStock/>	<br/>	<br/>*/}
					<div className="page-title">
						<div className="container-left">
							<p className="font-h2">{title}</p>
						</div>
					</div>

					<form onSubmit={this.handleSubmit} className={'bb-form ' + statusClass} ref={this.formRef}>
						<InputDate label="Fecha compra" name="date_sold" form={this} required/>
						<InputText label="Pedido" name="order_sold" form={this} required placeholder='Pedido XXXXX (Tienda)'/>
						<InputText label="Comentario" name="comment" form={this} />
						
						<div className="form-section">
							<p className="section-title font-h3">Productos vendidos</p>
							<div className="products-stock-container">
								<div className="products-search-container">
									<p className="section-title font-h4">Productos con número de serie</p>
									<InputSerialNumber label="Número de series" name={"serials"} form={this} />
									<p className="section-title font-h4">Productos sin número de serie</p>
									{this.state.values.productsNoSerials.map((product, i) => (
										<div className="section-item" key={'product'+i}>
											<div className="section-item-title">
												<div className="section-title-right">
													<p className="font-p button" onClick={this.deleteItem} name={"productsNoSerials"} index={i}>Eliminar</p>
												</div>
											</div>
											<InputIdProduct label="Producto" name={"productsNoSerials["+i+"].id_product"} form={this} extra={{serial:0}} required />
											<InputNumber label="Cantidad" name={"productsNoSerials["+i+"].quantity"} form={this} required />
										</div>
									))}
									<p className="font-p button" onClick={this.addItem} name={"productsNoSerials"}>Añadir producto</p>
								</div>
								<div className="products-summary-container">
									<p className="section-title font-h4">Productos añadidos</p>
									{this.state.products.map((product, i) => {
										totalProducts+=product.quantity;
										return (
											<div className="product-item" key={'product'+i}>
												<p className="font-p">{product.quantity} x {product.name}</p>
												<ul>
													{product.serials.map((serial, j)=>(<li key={i+'-'+j}>{serial}</li>))}
												</ul>
											</div>
										)
									})}
									<p className="font-p">Total de productos: {totalProducts}</p>
								</div>
							</div>
						</div>
					
						<div className="button-container font-center">
							<input className="button button-blue font-p" type="submit" value={buttonText}/>
						</div>	
						<p className="form-message font-p2">Form message: {this.state.formMessage.text}&nbsp;</p>
					</form>
				</div>

			</section>
		);
	}
}





export default pageRemoveStockOrder;




