import React, {Component} from "react";
// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";


class pageDashboard extends Component {
	
	constructor(props){
		super(props)
		this.state  = {
			
		}
		
	}
	
	componentDidMount(){
		console.log(this.state.page);
		/*GeneralAnimations.prepareGeneralAnimations();
		setTimeout( GeneralAnimations.animateGeneralElements, 400);*/

	}

	render(){
		
		return(
			<section className="page page-dashboard">
				<div className="page-main-container">
					<p>PANEL DE STK DISTRIBUCIONES TECNOLOGICAS</p>
				</div>
			</section>
		);
	}
}





export default pageDashboard;




