import React, {useContext} from "react";

import {NavLink, Link} from "react-router-dom";
import pageList from './pageList';
import Request, {CODES} from '../../utils/Request';

import UserContext from '../../utils/UserContext';

// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";


class pageListProductStock extends pageList {
	
	constructor(props){
		super(props)
		this.productId = this.props.match.params.productId;

		this.responseField = 'stock';
		this.apiName = 'stock/' + this.productId;
		this.listSlug = 'stock';
		this.singleItem = 'stock'; //Name of a single list item
		this.listTitle = 'Stock producto: ';
		this.hasRowOptions = false;

		// this.listWithoutLinks = true;
		this.itemLinkSlug = 'item';

		this.product = null;
	}
	
	componentDidMount(){

		Request.getAuth('/api/products/'+this.productId).then( res => {
			if(res.code === CODES.success){
				this.product = res.product;
				this.listTitle += this.product.sku;
				this.requestPages();
			}else{
				// console.log("need login");
			}
		});
	}

	getTable(pages){
		let table = {};
		table.headers = [];

		this.listSubTitle = 'Unidades en almacen: '+pages.length;

		if(pages.length > 0 && pages[0].serial_number){
			table.headers = [
				{
					slug: 'serial_number',
					name: 'Número Serie',
					class: 'item-big',
					canSearch: true,
				}
			]
		}
		table.headers = table.headers.concat([
			{
				slug: 'date_bought',
				name: 'Fecha Compra',
				class: 'item-medium',
				canSort: true,
				sortType: 'string',
			},
			{
				slug: 'provider_bought',
				name: 'Proveedor',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'invoice_bought',
				name: 'Factura Compra',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'comment',
				name: 'Comentario',
				class: 'item-medium',
			},
		]);
		table.rows = [];
		for(let i = 0; i < pages.length; i++){
			table.rows.push(this.createRow(pages[i]));
		}
		return table;
	}

	createRow(page){
		return {
			item : page,
			date_bought : page.date_bought,
			provider_bought : page.provider_bought,
			invoice_bought : page.invoice_bought,
			serial_number : page.serial_number,
			comment : page.comment,
		};
	}

	renderBeforeAddNew(){
		return <Link to={'/productos/'+this.productId} className="font-p button">Modificar producto</Link>;
	}
	renderAddNew(){
		return <Link to={'/stock/'+this.productId+'/vendidos/'} className="font-p button">Ver stock vendido</Link>;
	}
}





export default pageListProductStock;




