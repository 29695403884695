import React, {Component} from "react";
import { Link } from "react-router-dom";
// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";
import Request, {CODES} from '../../utils/Request';
import Form from "../../utils/Form";
import InputText from "../../utils/inputs/InputText";
import InputTrueFalse from "../../utils/inputs/InputTrueFalse";
import InputNumber from "../../utils/inputs/InputNumber";
import InputIdProduct from "../../utils/inputs/InputIdProduct";
import { translateStatus } from '../../utils/translations';


class pageOrder extends Form {
	
	constructor(props){
		super(props);
		this.formRef = React.createRef();
		
		this.from = null;
		if(this.props.location.state){
			this.from = this.props.location.state.from.pathname;
		}
		this.orderId = this.props.match.params.orderId;

		// this.handleInput = this.handleInput.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getFormMessage = this.getFormMessage.bind(this);
		this.isStatusSuccess = this.isStatusSuccess.bind(this);
	}
	
	componentDidMount(){
		/*GeneralAnimations.prepareGeneralAnimations();
		setTimeout( GeneralAnimations.animateGeneralElements, 400);*/
		Request.getAuth('/api/orders/'+this.orderId).then( res => {
			if(res.code === CODES.success){
				this.setState({
					currentOrder:res.result,
					order: res.result.order,
					products: res.result.products,
					loaded: true,
				});
			}else{
				// console.log("need login");
			}
		});
	}

	render(){
		if(!this.state.loaded) return null;

		let title = "Ficha del pedido";
		console.log(this.state.products)

		return(
			<section className="page page-course">
				<div className="page-main-container">					
					{/*<CurrentOrder/>	<br/>	<br/>*/}
					<div className="page-title">
						<div className="container-left">
							<p className="font-h2">{title}</p>
						</div>
						<div className="container-right font-right">
							<Link to={'/remove-stock-pedido/'+this.orderId} className="button button-default font-p font-white font-center">Registrar pedido</Link>
						</div>
					</div>
					<div className={'bb-form '}>
						<InputText label="Tienda" name="Tienda" form={this} defaultValue={this.state.order.store} readOnly={true} />
						<InputText label="Nº de pedido" name="Nº de pedido" form={this} defaultValue={this.state.order.order_number} readOnly={true} />
						<InputText label="Fecha" name="Fecha" form={this} defaultValue={new Date(this.state.order.time).toLocaleString()} readOnly={true} />
						<InputText label="Estado" name="Estado" form={this} defaultValue={translateStatus(this.state.order.status)} readOnly={true} />

						<div className="form-section">
							<p className="section-title font-h3">Productos</p>

							<div className="list-container bg-color-white">
								<div className="list-header">
									<div className={"list-item item-medium"}><p className="font-p font-semibold">Nombre</p></div>
									<div className={"list-item item-medium"}><p className="font-p font-semibold">Marca</p></div>
									<div className={"list-item item-medium"}><p className="font-p font-semibold">SKU (Factusol)</p></div>
									<div className={"list-item item-medium"}><p className="font-p font-semibold">SKU Soultek</p></div>
									<div className={"list-item item-medium"}><p className="font-p font-semibold">SKU STK</p></div>
									<div className={"list-item item-medium"}><p className="font-p font-semibold">Cantidad</p></div>
									<div className={"list-item item-medium"}><p className="font-p font-semibold">Almacen</p></div>
								</div>
								<div className="post-list">
									{this.state.products.map( (product, index) => {
											return 	<div key={index} className={"list-content "}>
												<div className="font-p list-item-container">
													<div className={"item-data item-medium"}>
														<p className="item-data-text mobile-header font-p font-black">Nombre: </p>
														<p className={"item-data-text font-p "}>{product.name}</p>
													</div>
													<div className={"item-data item-medium"}>
														<p className="item-data-text mobile-header font-p font-black">Marca: </p>
														<p className={"item-data-text font-p "}>{product.brand}</p>
													</div>
													<div className={"item-data item-medium"}>
														<p className="item-data-text mobile-header font-p font-black">SKU (Factusol): </p>
														<p className={"item-data-text font-p "}>{product.sku}</p>
													</div>
													<div className={"item-data item-medium"}>
														<p className="item-data-text mobile-header font-p font-black">SKU Soultek: </p>
														<p className={"item-data-text font-p "}>{product.sku_soultek}</p>
													</div>
													<div className={"item-data item-medium"}>
														<p className="item-data-text mobile-header font-p font-black">SKU STK: </p>
														<p className={"item-data-text font-p "}>{product.sku_stk}</p>
													</div>
													<div className={"item-data item-medium"}>
														<p className="item-data-text mobile-header font-p font-black">Cantidad: </p>
														<p className={"item-data-text font-p "}>{product.quantity}</p>
													</div>
													<div className={"item-data item-medium"}>
														<p className="item-data-text mobile-header font-p font-black">Almacen: </p>
														<p className={"item-data-text font-p "}>{product.location_store}</p>
													</div>
								 				</div>
											</div>
									})}
								</div>
							</div>
						</div>
					</div>

				</div>

			</section>
		);
	}
}





export default pageOrder;




