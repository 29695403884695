import React from "react";
import {NavLink, Link} from "react-router-dom";
import pageList from './pageList';
import Request, {CODES} from '../../utils/Request';
import { View, ViewHidden } from '../../utils/svgs';

// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";


class pageListUsers extends pageList {
	
	constructor(props){
		super(props);
		this.apiName = 'users';
		this.listSlug = 'usuarios';
		this.singleItem = 'usuario'; //Name of a single list item
		this.listTitle = 'Usuarios';
		
		this.hasRowOptions = false;
	}
	
	componentDidMount(){
		this.requestPages();
	}

	

	getTable(pages){
		let table = {};
		table.headers = [
			{
				slug: 'name',
				name: 'Nombre',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'email',
				name: 'Email',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'role',
				name: 'Role',
				class: 'item-medium',
				canSearch: false,
			},
		];
		table.rows = [];
		for(let i = 0; i < pages.length; i++){
			table.rows.push(this.createRow(pages[i]));
		}
		return table;
	}

	createRow(page){
		return {
			item : page,
			name : page.name,
			email : page.email,
			role : (page.role == 0)?'Administrador':'Editor',
		};
	}


}





export default pageListUsers;




