import React, {Component} from "react";
import { Link, Prompt } from "react-router-dom";
// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";
import Request, {CODES} from '../../utils/Request';
import Form from "../../utils/Form";
import InputText from "../../utils/inputs/InputText";
import InputTrueFalse from "../../utils/inputs/InputTrueFalse";
import InputNumber from "../../utils/inputs/InputNumber";
import InputIdProduct from "../../utils/inputs/InputIdProduct";
import InputDate from "../../utils/inputs/InputDate";
import InputSerialNumber from "../../utils/inputs/InputSerialNumber";
// import { ArrowFillUp, ArrowFillDown} from '../../utils/svgs';


class pageRemoveStock extends Form {
	
	constructor(props){
		super(props);
		this.formRef = React.createRef();
		this.state.products = [];
		this.state.totalProductsAdded = 0;
		
		this.units = null;
		this.from = null;
		if(this.props.location.state){
			this.from = this.props.location.state.from.pathname;
		}
		this.orderId = this.props.match.params.orderId;

		this.productsNoSerials = {};
		this.productsSummary = {
			noOrdered : {},
			ordered: {},
		}

		this.productsPending = {};

		this.totalProductsOrdered = 0;

		// this.handleInput = this.handleInput.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getFormMessage = this.getFormMessage.bind(this);
		this.isStatusSuccess = this.isStatusSuccess.bind(this);
	}
	
	componentDidMount(){
		// /*GeneralAnimations.prepareGeneralAnimations();
		// setTimeout( GeneralAnimations.animateGeneralElements, 400);*/



		let stock = {
			date_sold: '',
			order_sold: '',
			comment: '',
			serials: [],
			productsNoSerials: [/*{
				id_product: '',
				quantity: '',
			}*/],
		};

		Request.getAuth('/api/orders/'+this.orderId).then( res => {
			if(res.code === CODES.success){
				stock.orderId = this.orderId;
				stock.date_sold = this.getFormattedDate(res.result.order.time);
				stock.order_sold = 'Pedido ' + res.result.order.order_number + ' ('+res.result.order.store+')';
				let orderProducts = {};
				this.totalProductsOrdered = 0;
				for(let product of res.result.products){
					this.totalProductsOrdered += product.quantity;
					orderProducts[product.sku] = {
						quantityAdded : 0,
						quantity : product.quantity,
						product : product,
					}
					this.productsPending[product.sku] = {
						quantityAdded : 0,
						quantity : product.quantity,
						location_store: product.location_store,
					}
				}
				
				this.setState({
					currentStock:res.result,
					order: res.result,
					orderProducts: orderProducts,
					values: stock,
					loaded: true,
				});
			}else{
				// console.log("need login");
			}
		});

		let products = []

		Request.getAuth('/api/products/searcher/?serial=0').then( res => {
			if(res.code === CODES.success){
				let options = [];
				let label = this.state.label;
				for(let product of res.products){
					this.productsNoSerials[product.id] = product.sku;
				}
			}
		});
	}

	handleSubmit(event){
		event.preventDefault();
		let same_products_validated = true;
		if(this.state.totalProductsAdded != this.totalProductsOrdered){
			same_products_validated = window.confirm('No hay el mismo número de productos en el pedido de los incluidos. ¿Quieres continuar con la retirada del stock?');
		}
		if(same_products_validated){
			if(this.isValidForm()){
				if(!this.sendingForm){
					this.sendingForm = true;
					Request.postAuth('/api/stock/remove', this.state.values)
						.then(json => {
						 	this.sendingForm = false;
						 	if(json.code == CODES.success){
						 		// this.productId = json.product.id;
							 	this.setState({
							 		modified: false,
							 		formMessage: {
										status: 'success',
										text: 'Pedido registrado',
									}
							 	})
							 	window.alert('pedido registrado');
								// window.location = '/pedidos-registrados/'+this.state.values.date_sold+'/'+this.state.values.order_sold;
								window.location = '/pedidos';
						 	}else{
							 	this.setState({
							 		formMessage: {
										status: 'error',
										text: json.message,
									}
							 	})
						 	}
					});
				}
			}else{
				console.error("Input error: this.isValidForm() = false");
			}
		}
	}

	isStatusSuccess(){
		if(this.state.formMessage && this.state.formMessage.status !== 'success'){
			return false;
		}
		return true;
	}

	getFormattedDate(date){
		let d = new Date(date);

		let curr_date = ("0" + d.getDate()).slice(-2);
		let curr_month = ("0" + (d.getMonth() + 1)).slice(-2); //Months are zero based
		let curr_year = d.getFullYear();
		return (curr_year + "-" + curr_month + "-" + curr_date);
	}

	valueChanged(name, value){
		let serialProducts = {}
		for(let product of this.state.values.serials){
			if(!serialProducts[product.sku]){
				serialProducts[product.sku] = [];
			}
			serialProducts[product.sku].push(product.label);
		}

		let products = [];
		let totalProductsAdded = 0;
		for(let sku in serialProducts){
			totalProductsAdded += serialProducts[sku].length;
			products.push({
				name: sku,
				quantity: serialProducts[sku].length,
				serials: serialProducts[sku],
			})
			if(!this.state.orderProducts[sku]){
				if(!this.productsSummary.noOrdered[sku]){
					this.productsSummary.noOrdered[sku] = window.confirm('El producto ' + sku + ' no está en el pedido. ¿Quieres añadirlo?');
				}
			}else{
				if(!this.productsSummary.ordered[sku] && this.state.orderProducts[sku].quantity < serialProducts[sku].length){
					this.productsSummary.ordered[sku] = window.confirm('Del producto ' + sku + ' se han añadido más cantidad de la incluida en el pedido');
				}
			}
		}

		for(let product of this.state.values.productsNoSerials){
			if(product.id_product){
				let qty = Number(product.quantity) || 0;
				totalProductsAdded += qty;
				products.push({
					name: this.productsNoSerials[product.id_product],
					quantity: qty,
					serials: [],
				})

				let sku = this.productsNoSerials[product.id_product];
				if(!this.state.orderProducts[sku]){
					if(!this.productsSummary.noOrdered[sku]){
						this.productsSummary.noOrdered[sku] = window.confirm('El producto ' + sku + ' no está en el pedido. ¿Quieres añadirlo?');
					}
				}else{
					if(!this.productsSummary.ordered[sku] && this.state.orderProducts[sku].quantity < qty){
						this.productsSummary.ordered[sku] = window.confirm('Del producto ' + sku + ' se han añadido más cantidad de la incluida en el pedido');
					}
				}
			}
		}

		this.productsPending = {};
		for(let sku in this.state.orderProducts){
			this.productsPending[sku] = {
				quantityAdded : 0,
				quantity : this.state.orderProducts[sku].quantity,
				location_store: this.state.orderProducts[sku].location_store,
			}
		}
		for(let p of products){
			if(!this.productsPending[p.name]){
				this.productsPending[p.name] = {
					quantityAdded: 0,
					quantity: (this.state.orderProducts[p.name])?this.state.orderProducts[p.name].quantity:0,
				};
			}
			this.productsPending[p.name].quantityAdded = p.quantity;
		}

		this.setState({products: products, totalProductsAdded: totalProductsAdded})
	}

	render(){
		if(!this.state.loaded) return null;

		let buttonText = "Retirar stock";
		let title = "Registrar nuevo pedido saliente";
		
		let statusClass = '';
		if(!this.isStatusSuccess()){
			statusClass = 'form-error';
		}

		// let CurrentStock = () => (null);
		// if(this.state.values){
		// 	CurrentStock = ()=> {
		// 		return print_obj(this.state.currentStock);
		// 	};
		// 	function print_obj(obj){
		// 		return Object.keys(obj).map(key=>(
		// 			<p className="font-p" key={key}> <strong>{key}</strong>: {(typeof obj[key] != 'object')?obj[key] : print_obj(obj[key])}</p>
		// 		))
		// 	}
		// }

		let pendingProducts = [];
		for(let sku in this.productsPending){
			if(this.productsPending[sku].quantity != this.productsPending[sku].quantityAdded){
				pendingProducts.push({
					name: sku,
					quantity: this.productsPending[sku].quantity - this.productsPending[sku].quantityAdded,
					location_store: this.productsPending[sku].location_store,
				})
			}
		}

		return(
			<section className="page page-course">
				<Prompt when={this.state.modified} message={() => ('¿Seguro que quieres salir sin guardar?')} />
				<div className="page-main-container">					
					{/*<CurrentStock/>	<br/>	<br/>*/}
					<div className="page-title">
						<div className="container-left">
							<p className="font-h2">{title}</p>
						</div>
					</div>

					<form onSubmit={this.handleSubmit} className={'bb-form ' + statusClass} ref={this.formRef}>
						<InputDate label="Fecha compra" name="date_sold" form={this} required/>
						<InputText label="Pedido" name="order_sold" form={this} required />
						<InputText label="Comentario" name="comment" form={this} />
						
						<div className="form-section">
							<p className="section-title font-h3">Productos vendidos</p>
							<div className="products-stock-container">
								<div className="products-search-container">
									<p className="section-title font-h4">Productos con número de serie</p>
									<InputSerialNumber label="Número de series" name={"serials"} form={this} />
									<p className="section-title font-h4">Productos sin número de serie</p>
									{this.state.values.productsNoSerials.map((product, i) => (
										<div className="section-item" key={'product'+i}>
											<div className="section-item-title">
												<div className="section-title-right">
													<p className="font-p button" onClick={this.deleteItem} name={"productsNoSerials"} index={i}>Eliminar</p>
												</div>
											</div>
											<InputIdProduct label="Producto" name={"productsNoSerials["+i+"].id_product"} form={this} extra={{serial:0}} required />
											<InputNumber label="Cantidad" name={"productsNoSerials["+i+"].quantity"} form={this} required />
										</div>
									))}
									<p className="font-p button" onClick={this.addItem} name={"productsNoSerials"}>Añadir producto</p>
								</div>
								<div className="products-summary-container">
									<p className="section-title font-h4">Productos añadidos</p>
									{this.state.products.map((product, i) => {
										return (
											<div className="product-item" key={'product'+i}>
												<p className="font-p">{product.quantity} x {product.name}</p>
												<ul>
													{product.serials.map((serial, j)=>(<li key={i+'-'+j}>{serial}</li>))}
												</ul>
											</div>
										)
									})}
									<p className="font-p">Total de productos: {this.state.totalProductsAdded}</p>

									{(pendingProducts.length>0)?(
										<div className="summary-pending">
											<p className="section-title font-h4">Productos pendientes</p>
											{pendingProducts.map((product, i) => {
												console.log(product);
												return (
													<div className="product-item" key={'product_'+i+'_'+product.name+'_'+product.quantity}>
														<p className="font-p">{product.quantity} x {product.name + ((product.location_store)?(" ("+product.location_store+")"):'')}</p>
													</div>
												)
											})}
										</div>
									):null}
								</div>
							</div>
						</div>
					
						<div className="button-container font-center">
							<input className="button button-blue font-p" type="submit" value={buttonText}/>
						</div>	
						<p className="form-message font-p2">Form message: {this.state.formMessage.text}&nbsp;</p>
					</form>
				</div>

			</section>
		);
	}
}





export default pageRemoveStock;




