import React, {Component} from "react";



class InputNumber extends Component {
	constructor(props){
		super(props);

		this.state = {
			status : 'success',
			inputMessage : '',
			value: this.props.form.getValue(this.props.name) || this.props.defaultValue,
		}

		
		this.validateInput = this.validateInput.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
		
	}

	componentDidMount(){
		let value = this.props.form.getValue(this.props.name);
		if((value == "" || value == undefined) && this.props.defaultValue !== undefined){
			this.props.form.setValue(this.props.name, this.props.defaultValue);
			value = this.props.defaultValue;
		}
		this.validateInput(value);
	}

	componentWillUnmount(){
		this.props.form.setValidate(this.props.name, true);
	}

	validateInput(value){
		let valid = true;
		if(this.props.required && ( value === undefined || value === "")){
			valid = false;
			this.setState({
				status: "error",
				inputMessage: this.props.name + ' is required',
			});
		}else{
			this.setState({
				status: "success",
				inputMessage: '',
			})
		}

		this.props.form.setValidate(this.props.name, valid);
	}

	handleOnChange(event){
		this.validateInput(event.target.value);
		this.props.form.setValue(this.props.name, event.target.value);
	}

	render(){
		let statusClass = (this.state.status === "error") ? "input-error" : "";
		let value = this.props.form.getValue(this.props.name) || this.props.defaultValue ;
		return <label className={"font-p input-container "+statusClass} data-message={this.state.inputMessage}>
			{(this.props.label !== undefined) ? this.props.label + ": " : null}
			<input type="number" name={this.props.name} value={value} min={this.props.min} max={this.props.max} step={this.props.step} onChange={this.handleOnChange} readOnly={this.props.readOnly} />
		</label>
	}
}

export default InputNumber;