import { Component } from 'react';

class Form extends Component {
	constructor(props, initialState) {
		super(props);
		this.state = {
			values : initialState || {},
			
			valid : {},
			
			formMessage: {
				status: 'success', //success / error
				text: '',
			},
		 	loaded: false,
		 	modified: false,
		};

		//Init valid state
		for(let name in initialState){
			this.state.valid[name] = true;
		}

		// console.log("Init valid state", this.state.valid);

		this.sendingForm = false;

		this.getValue = this.getValue.bind(this);
		this.setValue = this.setValue.bind(this);
		this.setValidate = this.setValidate.bind(this);
		this.isValidForm = this.isValidForm.bind(this);
		this.getFormMessage = this.getFormMessage.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.addItem = this.addItem.bind(this);
		this.deleteItem = this.deleteItem.bind(this);
		this.isStatusSuccess = this.isStatusSuccess.bind(this);
		this.valueChanged = this.valueChanged.bind(this);

		window.document.addEventListener("keydown", function(event) {
			if (event.key === "Enter" && event.target.tagName === "INPUT") {
				event.preventDefault();
			}
		});
	}

	getValue(name){

		let {obj, key} = processObject(this.state.values, name);

		return obj[key];
	}

	
	setValidate(name, value, message){
		let currentState = this.state;
		currentState.valid[name] = value;
		this.setState(currentState);
	}

	setValue(name, value){
		let currentValues = this.state.values;

		let {obj,key,finalArrayIndex} = processObject(this.state.values, name);
		
		/*
		console.log("obj", obj);
		console.log("key", key);
		console.log("finalArrayIndex", finalArrayIndex);
		*/
	

		
		if(finalArrayIndex == null){
			obj[key] = value;
		}else{
			if(obj[key] == undefined){
				obj[key] = [];
			}
			obj[key][finalArrayIndex] = value;
		}

		

		this.setState({values: currentValues, modified: true}, ()=>this.valueChanged(name, value));
	}


	addItem(event){
		let currentValues = this.state.values;
		let name = event.currentTarget.getAttribute('name');

		let {obj, key} = processObject(currentValues, name);
		// console.log('add', obj, key);

		if(!obj[key]){
			obj[key] = [];
		}

		obj[key].push({});
		// currentValues.values[name] = value;
		this.setState({values: currentValues, modified: true}, ()=>this.valueChanged(name, obj[key]));
	}

	deleteItem(event){
		let currentValues = this.state.values;
		let name = event.currentTarget.getAttribute('name');
		let index = event.currentTarget.getAttribute('index');
		
		let {obj, key} = processObject(currentValues, name);
		// console.log('delete', obj, key, obj[key], index);

		if(key.indexOf('[') != -1){
			key = key.substring(0, key.indexOf('['));
		}
		obj[key].splice(index, 1);

		// console.log('--delete--', obj, key, obj[key], index);
		this.setState({values: currentValues, modified: true}, ()=>this.valueChanged(name, obj[key]));
	}

	isValidForm(){
		// console.log("isValidForm", this.state.valid);
		for(let input in this.state.valid){
			// console.log(input, this.state.valid[input]);
			if(this.state.valid[input] === false){
				this.setState({
					formMessage: {
						status: 'error',
						text: this.getFormMessage(),
					}
				})
				return false;
			}
		}

		//si el objeto está vacío no tiene valores del form no puede pasar
		this.setState({
			formMessage: {
				status: 'success',
				text: '',
			}
		});
		return true;
		
		
	}

	getFormMessage(){
		let inputErrorList = this.formRef.current.getElementsByClassName("input-error");
		if(inputErrorList.length > 0){
			// console.log("[FORM.jsx] El primer error es");
			let firstInputError = inputErrorList[0];
			// console.log(firstInputError);
			return firstInputError.getAttribute("data-message");
		}else{
			// console.log("[FORM.jsx] No hay mensaje de error");
		}




	}

	// setFormMessage(status, message){
	// 	this.setState({
	// 		formMessage: {
	// 			// status: status,
	// 			// text: message,
	// 			status: "error",
	// 			text: "test",
	// 		}
	// 	}, ()=>{console.log(this.state)});
	// }


	isStatusSuccess(){
		if(this.state.formMessage && this.state.formMessage.status !== 'success'){
			return false;
		}
		return true;
	}

	
	handleSubmit(event) {
		event.preventDefault();
		if(this.isValidForm()){
			console.log("V valid Form", this.state);
		}else{
			console.log("x Invalid Form", this.state);
		}
	}

	valueChanged(name, value){}


}

export default Form;

// "department.name"
// "department.chief[0]"
// "department.chief[1]"

function processObject(obj, key){
	if(key.indexOf('.') != -1) {
		var attrs = key.split('.');
		var tx = obj;
		for (var i = 0; i < attrs.length - 1; i++) {
			var isArray = attrs[i].indexOf('[') != -1;
			var isNestedArray = isArray && (i != attrs.length-1);
			var nestedArrayIndex = null;
			if(isArray){
				nestedArrayIndex = attrs[i].substring(attrs[i].indexOf('[') +1 , attrs[i].indexOf(']'));
				attrs[i] = attrs[i].substring(0, attrs[i].indexOf('['));
				if (tx[attrs[i]] == undefined){ 
					tx[attrs[i]] = [];
				}
				tx = tx[attrs[i]];
				if(isNestedArray){
					if(tx[nestedArrayIndex] == undefined){
						tx[nestedArrayIndex] = {};
					}
					tx = tx[nestedArrayIndex];
				}

			}else{
				if (tx[attrs[i]] == undefined){ 
					tx[attrs[i]] = {};
				}
				tx = tx[attrs[i]];
			}
		}
		return processObject(tx, attrs[attrs.length - 1]);
	}else{
		var finalArrayIndex = null;
		if(key.indexOf('[') != -1){
			finalArrayIndex = key.substring(key.indexOf('[') +1 , key.indexOf(']'));
			key = key.substring(0, key.indexOf('['));
		}
		return {obj: obj, key: key, finalArrayIndex: finalArrayIndex};
	}
}