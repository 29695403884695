import React from "react";
import {NavLink, Link} from "react-router-dom";
import pageListProductStock from './pageListProductStock';
import Request, {CODES} from '../../utils/Request';

// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";


class pageListProductStockSold extends pageListProductStock {
	
	constructor(props){
		super(props)
		this.apiName = 'stock/' + this.productId + '/sold';
		this.listTitle = 'Stock vendido producto: ';

		this.hasRowOptions = false;

		// this.listWithoutLinks = true;
		this.itemLinkSlug = 'item';

		this.product = null;

	}

	getTable(pages){
		let table = {};
		table.headers = [];

		this.listTitle += ' ('+pages.length+')';

		if(pages.length > 0 && pages[0].serial_number){
			table.headers = [
				{
					slug: 'serial_number',
					name: 'Número Serie',
					class: 'item-big',
					canSearch: true,
				}
			]
		}
		table.headers = table.headers.concat([
			{
				slug: 'date_bought',
				name: 'Fecha Compra',
				class: 'item-medium',
				canSort: true,
				sortType: 'string',
			},
			{
				slug: 'provider_bought',
				name: 'Proveedor',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'invoice_bought',
				name: 'Factura Compra',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'date_sold',
				name: 'Fecha Venta',
				class: 'item-medium',
				canSort: true,
				sortType: 'string',
			},
			{
				slug: 'order_sold',
				name: '# Pedido',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'comment',
				name: 'Comentario',
				class: 'item-medium',
			},
		]);
		table.rows = [];
		for(let i = 0; i < pages.length; i++){
			table.rows.push(this.createRow(pages[i]));
		}
		return table;
	}

	createRow(page){
		return {
			item : page,
			date_bought : page.date_bought,
			provider_bought : page.provider_bought,
			invoice_bought : page.invoice_bought,
			serial_number : page.serial_number,
			date_sold : page.date_sold,
			order_sold : page.order_sold,
			comment : page.comment,
		};
	}

	renderBeforeAddNew(){
		return <Link to={'/productos/'+this.productId} className="font-p button">Modificar producto</Link>;
	}
	
	renderAddNew(){
		return <Link to={'/stock/'+this.productId} className="font-p button">Ver stock</Link>;
	}
}





export default pageListProductStockSold;




