import React from 'react';

import {PERMISSIONS} from './Request';

const UserContext = React.createContext({
	'id': '',
	'name': '',
	'email': '',
	'role': '',
});

export default UserContext;