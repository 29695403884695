import React from "react";
import {Link} from "react-router-dom";
import pageList from './pageList';
import Request, {CODES} from '../../utils/Request';
import {RegisterStock} from '../../utils/svgs';
import {translateStatus} from '../../utils/translations'

// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";


class pageListOrdersEntry extends pageList {
	
	constructor(props){
		super(props)

		this.responseField = 'orders';
		this.apiName = 'orders-entry';
		this.listSlug = 'orders-entry';
		this.itemLinkSlug = 'entradas'
		this.singleItem = 'entrada'; //Name of a single list item
		this.listTitle = 'Entradas registradas';
		
		// this.itemLinkSlug = 'pedidos';

		this.hasRowOptions = false;

	}
	
	componentDidMount(){
		this.requestPages();
	}

	getTable(pages){
		let table = {};
		table.headers = [
			{
				slug: 'provider_bought',
				name: 'Proveedor',
				class: 'item-big',
				canSearch: true,
			},
			{
				slug: 'invoice_bought',
				name: 'Factura',
				class: 'item-big',
				canSearch: true,
			},
			{
				slug: 'date_bought',
				name: 'Fecha pedido',
				class: 'item-big',
				canSort: true,
				sortType: 'string'
			},
			{
				slug: 'n_prod',
				name: 'Número de productos',
				class: 'item-medium',
				canSort: true,
			},
		];
		table.rows = [];
		for(let i = 0; i < pages.length; i++){
			table.rows.push(this.createRow(pages[i]));
		}
		return table;
	}

	createRow(page){
		page.id = btoa(JSON.stringify({
			provider: page.provider_bought,
			date: page.date_bought,
			invoice: page.invoice_bought,
		}));
		return {
			item : page,
			id: page.id,
			provider_bought : page.provider_bought,
			invoice_bought : page.invoice_bought,
			date_bought : page.date_bought,
			n_prod : page.n_prod,
		};
	}

	
	renderAddNew(){
		return <Link to={'/add-stock/'} className="font-p button">Añadir entrada</Link>;
	}
	
}





export default pageListOrdersEntry;




