import React, {Component} from "react";
import CreatableSelect from 'react-select/creatable';
import Request, {CODES} from '../../utils/Request';



class InputNewSerialNumber extends Component {
	constructor(props){
		super(props);

		this.state = {
			status : 'success',
			inputMessage : '',
			inputValue: '',
			value: this.props.form.getValue(this.props.name) || this.props.defaultValue || [] ,
		}

		this.validateInput = this.validateInput.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
		this.setInputValue = this.setInputValue.bind(this);
		this.createOption = this.createOption.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	componentDidMount(){
		let value = this.props.form.getValue(this.props.name);
		if((value == undefined || value.length == 0) && this.props.defaultValue !== undefined){
			this.props.form.setValue(this.props.name, this.props.defaultValue);
			value = this.props.defaultValue;
		}
		this.validateInput(value);
	}

	componentWillUnmount(){
		this.props.form.setValidate(this.props.name, true);
	}

	validateInput(value){
		let valid = true;
		if(this.props.required && ( value === undefined || value.length == 0)){
			valid = false;
			this.setState({
				status: "error",
				inputMessage: this.props.name + ' is required',
			});
		}else{
			this.setState({
				status: "success",
				inputMessage: '',
			})
		}

		this.props.form.setValidate(this.props.name, valid);
	}

	handleOnChange(value){
		if(!value){
			value = [];
		}
		this.validateInput(value);
		this.props.form.setValue(this.props.name, value);
	}

	setInputValue(value){
		this.setState({inputValue:value});
	}

	createOption(label){
		return {
			label: label,
			value: label,
		}
	}

	handleKeyDown(event, oldValue){
		if (!this.state.inputValue) return;
		switch (event.key) {
			case 'Enter':
			case 'Tab':
				let value = [...oldValue, this.createOption(this.state.inputValue)];
				this.handleOnChange(value);
				this.setInputValue('');
				event.preventDefault();
		}
	}

	render(){
		let statusClass = (this.state.status === "error") ? "input-error" : "";
		let readOnlyClass = (this.props.readOnly) ? " read-only" : "";
		let value = this.props.form.getValue(this.props.name) || this.props.defaultValue || [] ;

		let input = 		
		    <label className={"font-p input-container "+statusClass+readOnlyClass} data-message={this.state.inputMessage}>
		    	{(this.props.label !== undefined)? 
		    		(<span className="label-text">{this.props.label + " ("+value.length+"): "}</span>)
		    		:
		    		(null)
		    	}

		    	<CreatableSelect
					components={{DropdownIndicator: null}}
					inputValue={this.state.inputValue}
					className="input-options-container select-custom-container"
					classNamePrefix="select-custom"
					isClearable={false}
					isMulti
					menuIsOpen={false}
					onChange={(newValue) => this.handleOnChange(newValue)}
					onInputChange={(newValue) => this.setInputValue(newValue)}
					onKeyDown={(event) => this.handleKeyDown(event, value)}
					placeholder="Introduce el número de serie y pulsa intro"
					value={value}
				/>
	        </label>
		return input;
	}
}

export default InputNewSerialNumber;