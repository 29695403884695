import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import Request, {CODES} from '../../utils/Request';
import UserContext from '../../utils/UserContext';
import arrowDown from '../../assets/icons/arrow-down.svg';



class MenuTop extends Component {
	
	constructor(props){
		super(props)
		
		this.user = null;
	}
	
	componentDidMount(){
		
	}


	handleLogout(){
		Request.logout().then(res => {
			if(res.code === CODES.success){
				window.location.reload();
			}
		});

	}


	render(){

		return(
	        <div className="menu-top ">
	        	<UserContext.Consumer>
					{user => {
						if(user.name === undefined){
							return <p>Login</p>
						}else{
							return (
	        					<React.Fragment>
	        						<div className="menu-top-left-container">
	        						</div>
	        						<div className="menu-top-right-container">
										{/*<a href="https://soultek.es" target="_blank" className="menu-top-item font-p button button-blue">Soultek</a>
										<a href="https://stkdistribuciones.es" target="_blank" className="menu-top-item font-p button button-blue">STK Distribuciones</a>*/}
						        		<p className="menu-top-item font-p ">{user.name}</p>
						        		<p className="menu-top-item button button-white font-p " onClick={this.handleLogout}>Cerrar sesión</p>
	        						</div>
		        				</React.Fragment>
							)
						}

					}}
				</UserContext.Consumer>
	        </div>
		);
	}
}


export default MenuTop;
