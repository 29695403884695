import React, {Component} from "react";
import { Link } from "react-router-dom";
// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";
import Request, {CODES} from '../../utils/Request';
import Form from "../../utils/Form";
import InputText from "../../utils/inputs/InputText";
import InputTrueFalse from "../../utils/inputs/InputTrueFalse";
import InputNumber from "../../utils/inputs/InputNumber";
import InputIdProduct from "../../utils/inputs/InputIdProduct";
import InputRadio from "../../utils/inputs/InputRadio";
// import { ArrowFillUp, ArrowFillDown} from '../../utils/svgs';


class pageProduct extends Form {
	
	constructor(props){
		super(props);
		this.formRef = React.createRef();
		
		this.units = null;
		this.from = null;
		if(this.props.location.state){
			this.from = this.props.location.state.from.pathname;
		}
		this.productId = this.props.match.params.productId;

		// this.handleInput = this.handleInput.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getFormMessage = this.getFormMessage.bind(this);
		this.isNew = this.isNew.bind(this);
		this.isStatusSuccess = this.isStatusSuccess.bind(this);
	}
	
	componentDidMount(){
		/*GeneralAnimations.prepareGeneralAnimations();
		setTimeout( GeneralAnimations.animateGeneralElements, 400);*/
		Request.getAuth('/api/products/'+this.productId).then( res => {
			if(res.code === CODES.success){
				this.setState({
					currentProduct:res.product,
					values: res.product,
					loaded: true,
				});
			}else{
				// console.log("need login");
			}
		});
	}


	handleSubmit(event){
		event.preventDefault();
		if(this.isValidForm()){
			if(!this.sendingForm){
				this.sendingForm = true;
				Request.postAuth('/api/products/'+this.productId, this.state.values)
					.then(json => {
					 	this.sendingForm = false;
					 	if(json.code == CODES.success){
					 		this.productId = json.product.id;
						 	this.setState({
						 		formMessage: {
									status: 'success',
									text: 'Producto actualizado',
								}
						 	})
						 	window.alert('Producto actualizado');
					 	}else{
						 	this.setState({
						 		formMessage: {
									status: 'error',
									text: json.message,
								}
						 	})
					 	}
				});
			}
		}else{
			console.error("Input error: this.isValidForm() = false");
		}
	}

	isNew(){
		if(this.productId === "new-temp"){
			return true;
		}
		return false;
	}


	isStatusSuccess(){
		if(this.state.formMessage && this.state.formMessage.status !== 'success'){
			return false;
		}
		return true;
	}

	render(){
		if(!this.state.loaded) return null;

		let buttonText = "Actualizar producto";
		let title = "Actualizar producto";
		
		if(this.isNew()){
			title = buttonText = "Crear producto";
		}
		
		let statusClass = '';
		if(!this.isStatusSuccess()){
			statusClass = 'form-error';
		}

		// let CurrentProduct = () => (null);
		// if(this.state.currentProduct){
		// 	CurrentProduct = ()=> {
		// 		return Object.keys(this.state.currentProduct).map(key=>(
		// 			<p className="font-p" key={key}> <strong>{key}</strong>: {(typeof this.state.currentProduct[key] != 'object')?this.state.currentProduct[key] : "{}"}</p>
		// 		))};
		// }

		return(
			<section className="page page-course">
				<div className="page-main-container">					
					{/*<CurrentProduct/>	<br/>	<br/>*/}
					<div className="page-title">
						<div className="container-left">
							<p className="font-h2">{title}</p>
						</div>
						<div className="container-right font-right">
							<Link to={'/stock/'+this.productId} className="button button-default font-p font-white font-center">Ver stock</Link>
							<Link to={'/stock/'+this.productId+'/vendidos/'} className="button button-default font-p font-white font-center">Ver stock vendido</Link>;
						</div>
					</div>

					<form onSubmit={this.handleSubmit} className={'bb-form ' + statusClass} ref={this.formRef}>
						<InputText label="Nombre" name="name" form={this} required />
						<InputText label="Marca" name="brand" form={this} />
						
						<InputText label="SKU (Factusol)" name="sku" form={this} required />
						<InputText label="SKU Soultek" name="sku_soultek" form={this} />
						<InputText label="SKU STK" name="sku_stk" form={this} />
						
						<InputText label="Proveedor" name="provider" form={this} />
						
						<InputText label="Código de barras" name="bar_code" form={this} />

						<InputText label="Almacen" name="location_store" form={this} />
						
						<InputTrueFalse label="¿Tiene número de serie?" name="has_serial" form={this} />
						<InputTrueFalse label="Sincronizar stock" name="sync_stock" form={this} />
						<InputRadio label="¿Permitir reservas?" name="backorders" values={{no: 'No permitir', notify: 'Permitir, pero se avisará al cliente', yes: 'Permitir'}} form={this} />
						<InputNumber label="Umbral de pocas existencias" name="low_stock_amount" form={this}/>
						
						<div className="form-section">
							<InputTrueFalse label="¿Es un Kit de productos?" name="is_composite" form={this} />

							{(this.state.values.is_composite)?(
								<div className="form-section">
									<p className="section-title font-h3">Componentes del KIT</p>
									{this.state.values.components.map((component, i) => (
										<div className="section-item" key={'component'+i}>
											<div className="section-item-title">
												<div className="section-title-right">
													<p className="font-p button" onClick={this.deleteItem} name={"components"} index={i}>Eliminar</p>
												</div>
											</div>
											<InputIdProduct label="Producto" name={"components["+i+"].id_component"} form={this} required />
											<InputNumber label="Cantidad" name={"components["+i+"].quantity"} form={this} required />
										</div>
									))}
									<p className="font-p button" onClick={this.addItem} name={"components"}>Añadir componente</p>
								</div>
							):null}
						</div>
						<div className="button-container font-center">
							<input className="button button-blue font-p" type="submit" value={buttonText}/>
						</div>	
						<p className="form-message font-p2">Form message: {this.state.formMessage.text}&nbsp;</p>
					</form>
				</div>

			</section>
		);
	}
}





export default pageProduct;




