import React, {Component} from "react";
import { Link } from "react-router-dom";
// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";
import Request, {CODES} from '../../utils/Request';
import Form from "../../utils/Form";
import InputText from "../../utils/inputs/InputText";
import InputTrueFalse from "../../utils/inputs/InputTrueFalse";
import InputDate from "../../utils/inputs/InputDate";
import InputIdProduct from "../../utils/inputs/InputIdProduct";
// import { ArrowFillUp, ArrowFillDown} from '../../utils/svgs';


class pageStockItem extends Form {
	
	constructor(props){
		super(props);
		this.formRef = React.createRef();
		
		this.units = null;
		this.from = null;
		if(this.props.location.state){
			this.from = this.props.location.state.from.pathname;
		}
		this.stockId = this.props.match.params.stockId;

		// this.handleInput = this.handleInput.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getFormMessage = this.getFormMessage.bind(this);
		this.isNew = this.isNew.bind(this);
		this.isStatusSuccess = this.isStatusSuccess.bind(this);
	}
	
	componentDidMount(){
		/*GeneralAnimations.prepareGeneralAnimations();
		setTimeout( GeneralAnimations.animateGeneralElements, 400);*/
		Request.getAuth('/api/stock-item/'+this.stockId).then( res => {
			if(res.code === CODES.success){
				this.setState({
					currentProduct:res.item,
					values: res.item,
					loaded: true,
				});
			}else{
				// console.log("need login");
			}
		});
	}


	handleSubmit(event){
		event.preventDefault();
		if(this.isValidForm()){
			if(!this.sendingForm){
				this.sendingForm = true;
				Request.postAuth('/api/stock-item/'+this.stockId, this.state.values)
					.then(json => {
					 	this.sendingForm = false;
					 	if(json.code == CODES.success){
						 	this.setState({
						 		formMessage: {
									status: 'success',
									text: 'Item actualizado',
								}
						 	})
						 	window.alert('Artículo actualizado');
						 	let location = '/stock/'+this.state.values.id_product;
						 	if(this.state.values.order_sold){
						 		location+='/vendidos/';
						 	}
							window.location = location;
					 	}else{
						 	this.setState({
						 		formMessage: {
									status: 'error',
									text: json.message,
								}
						 	})
					 	}
				});
			}
		}else{
			console.error("Input error: this.isValidForm() = false");
		}
	}

	isNew(){
		if(this.stockId === "new-temp"){
			return true;
		}
		return false;
	}


	isStatusSuccess(){
		if(this.state.formMessage && this.state.formMessage.status !== 'success'){
			return false;
		}
		return true;
	}

	render(){
		if(!this.state.loaded) return null;

		let buttonText = "Actualizar item";
		let title = "Actualizar item";
		
		let statusClass = '';
		if(!this.isStatusSuccess()){
			statusClass = 'form-error';
		}

		// let CurrentProduct = () => (null);
		// if(this.state.currentProduct){
		// 	CurrentProduct = ()=> {
		// 		return Object.keys(this.state.currentProduct).map(key=>(
		// 			<p className="font-p" key={key}> <strong>{key}</strong>: {(typeof this.state.currentProduct[key] != 'object')?this.state.currentProduct[key] : "{}"}</p>
		// 		))};
		// }

		return(
			<section className="page page-course">
				<div className="page-main-container">					
					{/*<CurrentProduct/>	<br/>	<br/>*/}
					<div className="page-title">
						<div className="container-left">
							<p className="font-h2">{title}</p>
						</div>
						<div className="container-right font-right">
							<Link to={'/stock/'+this.state.values.id_product} className="button button-default font-p font-white font-center">Ver stock</Link>
						</div>
					</div>

					<form onSubmit={this.handleSubmit} className={'bb-form ' + statusClass} ref={this.formRef}>
						<InputIdProduct label="Producto" name="id_product" form={this} required />
						
						<div className="form-section">
							<p className="section-title font-h3">Compra</p>
							<InputText label="Proveedor" name="provider_bought" form={this} required />
							<InputDate label="Fecha de compra" name="date_bought" form={this} required />
							<InputText label="Factura compra" name="invoice_bought" form={this} required />
							<InputText label="Número de serie" name="serial_number" form={this} />
						</div>
						
						<div className="form-section">
							<p className="section-title font-h3">Venta</p>
							<InputDate label="Fecha de venta" name="date_sold" form={this} />
							<InputText label="Pedido" name="order_sold" form={this} />
							<InputText label="Comentario" name="comment" form={this} />
						</div>

						<div className="button-container font-center">
							<input className="button button-blue font-p" type="submit" value={buttonText}/>
						</div>	
						<p className="form-message font-p2">Form message: {this.state.formMessage.text}&nbsp;</p>
					</form>
				</div>

			</section>
		);
	}
}





export default pageStockItem;




