import React, {Component} from "react";



class InputRadio extends Component {
	constructor(props){
		super(props);
		this.state = {
			status: 'success',
			inputMessage: '',
		}

		this.validateInput = this.validateInput.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
	}

	componentDidMount(){
		this.validateInput(this.props.form.getValue(this.props.name));
	}

	componentWillUnmount(){
		this.props.form.setValidate(this.props.name, true);
	}

	validateInput(value){
		// console.log("Validate Input RADIO ----> ", this.state);
		let valid = true;
		if(this.props.required && ( value === undefined || value === "")){
			valid = false;	
			this.setState({
				status: "error",
				inputMessage: this.props.name + ' is required',
			});
		}else{
			this.setState({
				status: "success",
				inputMessage: '',
			})
		}
		this.props.form.setValidate(this.props.name, valid);
	}

	handleOnChange(event){
		this.validateInput(event.target.value);
		this.props.form.setValue(this.props.name, event.target.value);
	}

	
	render(){
		let arrayValues = Object.keys(this.props.values);
		return (
			<div className="font-p input-container checkbox-container ">
	          	<span className="label-text label-header">{(this.props.label !== undefined) ? this.props.label + ": " : null}</span>
	          	<div className="input-options-container">
					{arrayValues.map( (key, i)=>(
			    		<label key={i}>
							{/*<input type="radio" value={key} name={this.props.name} checked={ checkedValue.indexOf(key)>=0 } onChange={this.handleOnChange} />*/}
							<input type="radio" value={key}  checked={ (this.props.form.getValue(this.props.name)==key ? true : false) } onChange={this.handleOnChange} />
							<span className="radio-label">{(typeof this.props.values[key] !== 'object') ? this.props.values[key] : ((this.props.values[key].title) ? this.props.values[key].title : key)}</span>
							{/*<span className="label-text">{this.props.values[key]}</span>*/}
		        		</label>
					) )}
				</div>
			</div>
		);
	}
}

export default InputRadio;