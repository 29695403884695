import React, {Component} from "react";

import {Redirect, Switch, Route} from "react-router-dom";

import FormLogin from "../components/FormLogin";
import FormRequestResetPassword from "../components/FormRequestResetPassword";
import FormResetPassword from "../components/FormResetPassword";

class pageLogin extends Component {
	
	constructor(props){
		super(props);

		this.state = {
			redirectTo: null,
		}

		this.from = '/';
		if(this.props.location.state){
			this.from = this.props.location.state.from.pathname;
		}

		this.redirectFrom = this.redirectFrom.bind(this);
	}

	redirectFrom(){
		this.setState({redirectTo: this.from})
	}
	
	render(){
		if(this.state.redirectTo){
			return <Redirect to={this.state.redirectTo} />
		}
		return(
			<section className="page page-login bg-color-gray-light">
				<div className="login-container">
					<div className="login-header font-center">
	        			{/*<img className="logo" src={require("../assets/logos/logo.png")} alt="logo"/>*/}
	        			<p className="font-h4">STK Distribuciones Tecnológicas</p>
					</div>
					
	 				<Switch>
						<Route path="/reset-password/:hash" component={(props) => (<FormResetPassword redirectFrom={this.redirectFrom} hash={props.match.params.hash}/>)} />
						<Route path="/reset-password" component={(props) => (<FormRequestResetPassword redirectFrom={this.redirectFrom} />)} />
						<Route path="/login" component={(props) => (<FormLogin redirectFrom={this.redirectFrom} />)} />
	  				</Switch>
				</div>
			</section>
		);
	}
}


export default pageLogin;
