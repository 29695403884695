import React, {Component} from "react";




class InputPassword extends Component {
	constructor(props){
		super(props);

		this.state = {
			status : 'success',
			confirmValue : '', //this.props.form.getValue(this.props.name),
			inputMessage : '',
			value: this.props.form.getValue(this.props.name),
		}

		this.validateInput = this.validateInput.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
		this.validateInputConfirm = this.validateInputConfirm.bind(this);
		this.handleOnChangeConfirm = this.handleOnChangeConfirm.bind(this);

	}

	componentDidMount(){
		if(this.props.form.getValue(this.props.name) == undefined && this.props.defaultValue !== undefined){
			this.props.form.setValue(this.props.name, this.props.defaultValue);
		}
		this.validateInput(this.props.form.getValue(this.props.name));
	}

	componentWillUnmount(){
		this.props.form.setValidate(this.props.name, true);
	}


	validateInput(value){
		let valid = true;
		if(this.props.required && ( value === undefined || value === "")){
			valid = false;
			this.setState({
				status: "error",
				inputMessage: this.props.name + ' es obligatorio.',
			});
		}else if(value && value.length < 6){
			valid = false;
			this.setState({
				status: "error",
				inputMessage: this.props.name + ' minimo 6 caracteres.',
			});
		}


		this.props.form.setValidate(this.props.name, valid);
		if(valid && this.props.confirm){
			this.validateInputConfirm(this.state.confirmValue, value);
		}
	}

	handleOnChange(event){
		this.validateInput(event.target.value);
		this.props.form.setValue(this.props.name, event.target.value);
	}

	handleOnChangeConfirm(event){
		let passwordValue = this.props.form.getValue(this.props.name);
		this.setState({confirmValue: event.target.value}, ()=>this.validateInput(passwordValue) );
	}

	validateInputConfirm(value, passwordValue){
		let valid = true;
		if(value !== passwordValue && passwordValue !== undefined){
			valid = false;
			this.setState({
				status: "error",
				inputMessage: this.props.name+' no coinciden.',
			});
		}
		this.props.form.setValidate(this.props.name, valid);
	}

	render(){
		let statusClass = (this.state.status === "error") ? "input-error" : "";
		let input = 
			<React.Fragment>
			    <label className={"font-p input-container "+statusClass} data-message={this.state.inputMessage}>
		        	{(this.props.label !== undefined)? 
			    		(<span className="label-text">{this.props.label + ": "}</span>)
			    		:
			    		(null)
			    	}
		        	<input type="password" name={this.props.name} value={this.state.value} onChange={this.handleOnChange} placeholder={this.props.placeholder} />
		        </label>
		        {(this.props.confirm)?
			        <label className={"font-p input-container "+statusClass} data-message={this.state.inputMessage}>
			        	{(this.props.label !== undefined)? 
				    		(<span className="label-text">Confirmar {this.props.label + ": "}</span>)
				    		:
				    		(null)
				    	}
			        	<input type="password" name={this.props.name+"Confirm"} value={this.state.value} onChange={this.handleOnChangeConfirm} placeholder={"Confirmar "+this.props.placeholder}/>
			        </label>
			        : null
		        }

	        </React.Fragment>
		return input;
	}
}

export default InputPassword;