import React, {Component} from "react";
import Select from 'react-select';
import Request, {CODES} from '../../utils/Request';
import Creatable from 'react-select/creatable';



class InputProvider extends Component {
	constructor(props){
		super(props);

		this.state = {
			status : 'success',
			inputMessage : '',
			inputValue: '',
			value: this.props.form.getValue(this.props.name) || this.props.defaultValue || [] ,
			options: false,
		}

		this.validateInput = this.validateInput.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
	}

	componentDidMount(){
		let value = this.props.form.getValue(this.props.name);
		if((value == undefined || value.length == 0) && this.props.defaultValue !== undefined){
			this.props.form.setValue(this.props.name, this.props.defaultValue);
			value = this.props.defaultValue;
		}
		this.validateInput(undefined);

		Request.getAuth('/api/providers/').then( res => {
			if(res.code === CODES.success){
				let options = [];
				for(let provider of res.providers){
					options.push({
						value: provider.provider_bought,
						label: provider.provider_bought,
					});
				}
				this.setState({options: options}, ()=>this.validateInput(value));
			}
		});
	}

	componentWillUnmount(){
		this.props.form.setValidate(this.props.name, true);
	}

	validateInput(value){
		let valid = true;
		if(this.props.required && ( value === undefined || value.length == 0)){
			valid = false;
			this.setState({
				status: "error",
				inputMessage: this.props.name + ' is required',
			});
		}else{
			this.setState({
				status: "success",
				inputMessage: '',
			})
		}

		this.props.form.setValidate(this.props.name, valid);
	}

	handleOnChange(value){
		this.validateInput(value);
		this.props.form.setValue(this.props.name, value);
	}

	render(){

		if(!this.state.options) return null;
		let statusClass = (this.state.status === "error") ? "input-error" : "";
		let readOnlyClass = (this.props.readOnly) ? " read-only" : "";
		let provider = this.props.form.getValue(this.props.name) || this.props.defaultValue || [] ;

		let value = null;
		
		if(!provider || provider.length>0){
			value={value: provider, label: provider};
		}

		let input = 		
		    <label className={"font-p input-container "+statusClass+readOnlyClass} data-message={this.state.inputMessage}>
		    	{(this.props.label !== undefined)? 
		    		(<span className="label-text">{this.props.label}:</span>)
		    		:
		    		(null)
		    	}
		    	<Creatable 
		    		options={this.state.options}
					className="input-options-container select-custom-container"
					classNamePrefix="select-custom"
					onChange={(option)=>this.handleOnChange(option.value)}
					value = {value}
		    	/>
	        </label>
		return input;
	}
}

export default InputProvider;