import React, {Component} from "react";
import { Link, Redirect } from "react-router-dom";

import Auth from "../../utils/Auth";
import Request, {CODES} from '../../utils/Request';

import ConfirmPopup from "../components/confirmPopup";
import { DeleteSvg, CloseSvg } from '../../utils/svgs';


class editList extends Component {

	constructor(props) {
		super(props);
		this.state  = {
			table : [],
			confirmPopup : false,
			deleteElementIndex: -1,
			redirectTo: null,
		}
		this.sort = null;
		this.listSlug = this.props.match ? this.props.match.params.slug : null;
		this.apiName = this.props.match ? this.props.match.params.apiName : null;
		this.list = null;
		this.errorMessage = 'No hay datos para mostrar';
		this.listTitle = '';
		this.itemLinkSlug = '';

		this.hasRowOptions = true;

		this.refPopupSubtypes = React.createRef();

		this.handleDeletePost = this.handleDeletePost.bind(this);
		this.handleChangeSearchText = this.handleChangeSearchText.bind(this);
		this.openSubtypePopup = this.openSubtypePopup.bind(this);
		this.renderAddNew = this.renderAddNew.bind(this);
	}

	requestPages(){
		this.responseField = this.responseField || this.apiName;
		this.listTitle = this.listTitle || this.responseField;
		this.itemLinkSlug = this.itemLinkSlug || this.listSlug
		Request.getAuth('/api/'+this.apiName)
			.then(json => {
				if( json.code === CODES.success ){
					this.list = json;
					this.setPages(json[this.responseField]);
				}else{
					
				}
		});
	}

	componentDidMount(){
		this.requestPages();
	}
	
	setPages(pages){
		let table = this.getTable(pages);
		this.setState({table: table});
	}

	changeHeaderSort(header){
		if(!header.canSort){
			return;
		}
		if(!this.sort || this.sort.header.slug !== header.slug){
			this.sort = {};
			this.sort.header = header;
			this.sort.asc = true;
		}else{
			this.sort.asc = !this.sort.asc;
		}
		this.forceUpdate();
	}

	sortTable(){
		if(this.sort){
			let header = this.sort.header;

			this.state.table.rows.sort((a, b) => {
				if(this.sort.asc){
					if(header.sortType === 'string'){
						return a[header.slug].localeCompare(b[header.slug])
					}else if(header.sortType === 'date'){
						return (new Date(a[header.slug])).getTime() - (new Date(b[header.slug])).getTime();
					}else{
						return a[header.slug] - b[header.slug];
					}
				}else{
					if(header.sortType === 'string'){
						return b[header.slug].localeCompare(a[header.slug])
					}else if(header.sortType === 'date'){
						return (new Date(b[header.slug])).getTime() - (new Date(a[header.slug])).getTime();
					}else{
						return b[header.slug] - a[header.slug];
					}
				}
			})
		}
	}

	getTable(pages){
		let table = {};
		table.headers = [
			{
				slug: 'title',
				name: 'Title',
				class: '',
				canSearch: true,
			},
		];
		table.rows = [];
		for(let i = 0; i < pages.length; i++){
			table.rows.push(this.createRow(pages[i]));
		}
		return table;
	}
	
	createRow(page){
		return {
			item : page,
			title: page.title,
		};
	}

	setDisplay(event, id, currentDisplay, index){
		event.stopPropagation();
		event.preventDefault();
		Request.patch('/cms/page/'+id+'/display', {display: !currentDisplay})
		.then(json => {
			if( json.code === CODES.success ){
				this.setDisplayPage(index, json.page);
			}else{
				
			}
		});
	}

	setDisplayPage(index, page){
		let table = this.state.table;
		table.rows.splice(index,1,this.createRow(page));
		this.setState(this.state);

	}

	handleDeletePost(event,item, newItemId, index){
		console.log("handleDeletePost!!!!!!!!!!!!!!!!!!!!")

		event.stopPropagation();
		event.preventDefault();
		const itemSlug = this.state.confirmPopup.id;
		this.setState({confirmPopup:false});
		Request.delete('/api/'+this.apiName+'/'+itemSlug)
			.then(json => {
			 	this.sendingForm = false;
				if( json.code === CODES.success){
					let index = this.state.deleteElementIndex;
					this.state.table.rows.splice(index,1);
					this.setState(this.state);
					// window.location.reload(false);
				}else{
					alert(json.message);
				}
		});
	}

	handleChangeSearchText(event){
		let filteredPages = this.list[this.responseField];
		if(event.target.value.length > 0){
			this.errorMessage = 'No se han encontrado resultados.'
			let headers = this.state.table.headers.filter((header) => {
				return header.canSearch;
			});
			filteredPages = this.list[this.responseField].filter((page) => {
				let row = this.createRow(page);
				for(let i = 0; i < headers.length; i++){
					if(String(row[headers[i].slug]).toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0){
						return true;
					}
				}
				return false;
			})
		}
		this.setPages(filteredPages);
	}

	openSubtypePopup(){
		this.refPopupSubtypes.current.openPopup();
	}

	renderAddNew(){
		let singleTitle = 'Añadir nuevo';
		return <Link to={'/'+this.listSlug+'/new-temp'} className="button button-default font-p font-white font-center">{singleTitle}</Link>
	}
	renderBeforeAddNew(){
		return null;
	}
	renderAfterAddNew(){
		return null;
	}

	renderDeleteIcon(row, index){
		return <div className="icon-container font-right" 
					onClick={ (event)=>{
						event.stopPropagation();
						event.preventDefault();
						this.setState({confirmPopup:row.item, deleteElementIndex: index});
					}}>

					<DeleteSvg/>
				</div>
	}

	renderBeforeDeleteIcon(row, index){
		return null;
	}
	renderAfterDeleteIcon(row, index){
		return null;
	}



	renderList(){
		return(
			<div className="list-container bg-color-white">
				{(this.list[this.responseField].length > 0) ? (
					<div className="list-search">
						<input className="search" onChange={this.handleChangeSearchText} type="text" placeholder="Buscar"/>
					</div>
				) : null }
				{this.state.table.rows.length > 0 ? (
					<React.Fragment>
						<div className="list-header">
							{this.state.table.headers.map( (header, index) => {
								let headerClass = '';
								if(header.class){
									headerClass = header.class;
								}
								if(header.canSort){
									if(this.sort && this.sort.header.slug === header.slug){
										if(this.sort.asc){
											headerClass += ' sort-asc';
										}else{
											headerClass += ' sort-desc';
										}
									}
									headerClass += ' item-can-sort';
								}

								return <div className={"list-item " + headerClass} key={index} onClick={() => {this.changeHeaderSort(header); }}><p className="font-p font-semibold">{header.name}</p></div>
							})}
							<div className= {"list-item item-small " + (this.hasRowOptions ? "" : "no-row-options")}><p className="font-p font-semibold">&nbsp;</p></div>
						</div>
						<div className="post-list">
							{this.state.table.rows.map( (row, index) => {
								let RowContent = () => 
									<React.Fragment>
										<div className="font-p list-item-container">
											{this.state.table.headers.map( (header, index2) => {
												let headerClass = (header.class) ? header.class : '';
												let fieldClass = '';
												let rowData = getRowData(row[header.slug], header.type);
												return <React.Fragment key={index2}>
															<div className={"item-data " + headerClass}>
																<p className="item-data-text mobile-header font-p font-black">{this.state.table.headers[index2].name + ": "}</p>
																<p className={"item-data-text font-p "+ fieldClass}>{rowData}</p>
															</div>
														</React.Fragment>
											})}
											<div className={"container-delete-mobile item-data item-small " + (this.hasRowOptions ? "" : "no-row-options")}>
												{this.renderBeforeDeleteIcon(row, index)}
												{this.renderDeleteIcon(row, index)}
												{this.renderAfterDeleteIcon(row, index)}
											</div>
						 				</div>
									</React.Fragment>
								
								if(this.listWithoutLinks){
									return 	<div key={index} className={"list-content "}>
												<RowContent/>
											</div>
								}else{
									return 	<Link key={index} to={'/'+this.itemLinkSlug+'/'+row.item.id} className={"list-content "+ (row.item.id ? "" : "no-link" )}>
												<RowContent/>
											</Link>
								}
									
								
							})}
						</div>
					</React.Fragment>
				) : (
					<div className="list-error-message">
						<p className="font-p font-semibold">{this.errorMessage}</p>
					</div>
				)}
			</div>
		)
	}

	render(){
		if(this.state.redirectTo){
			return <Redirect to={this.state.redirectTo} />
		}
		if(this.list === null) return null;
		this.sortTable();
		// let singleTitle = (this.list.listData.singleName === undefined) ? 'Add new' : "Add " + this.list.listData.singleName.toLowerCase();
		let confirmPopupMessage = (this.state.confirmPopupData && this.state.confirmPopupData.message) ? this.state.confirmPopupData.message : ("¿Estás seguro que quieres borrar este "+this.singleItem+"? "+((this.state.confirmPopup)?(this.state.confirmPopup.sku):''));

		return(
			<div className="page-edit-list main-container bg-color-blue-primary page-main-container">
				<div className="page-title">
					<div className="container-left">
						{/*<p className="font-h3 font-semibold font-uppercase font-black">{this.list.listData.title}</p>*/}
						<p className="font-h3 font-semibold font-uppercase font-black">{this.listTitle}</p>
						{(this.listSubTitle)?
							<p className="font-h5 font-black pre-line">{this.listSubTitle}</p>
							:null
						}
					</div>
					<div className="container-right font-right">
						{this.renderBeforeAddNew()}
						{this.renderAddNew()}
						{this.renderAfterAddNew()}
					</div>
				</div>

				{this.renderList()}
				
				<ConfirmPopup 
					description={confirmPopupMessage}
					visible={this.state.confirmPopup}
					cancel={() => {this.setState({confirmPopup:false});} }
					success={(event) => {
						if(this.state.confirmPopupData && this.state.confirmPopupData.success){
							return this.state.confirmPopupData.success();
						}else{
							return this.handleDeletePost(event)
						}
					}}
				/>
			</div>
		);		
	}
}


function getRowData(data, type){
	let rowData = data;
	if(rowData === false){
		rowData = "No";
	}else if(rowData === true){
		rowData = "Si";
	}

	if(type === 'date'){
		rowData = new Date(rowData).toLocaleDateString();
		// rowData = new Intl.DateTimeFormat('en-GB').format(rowData);
	}

	return rowData;
}


export default editList;