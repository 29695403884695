// import Config from "./config";
import {Auth} from './Auth';

const Request = {
	get(url, headers = {}){
		return fetch(url,{
			headers: headers})
		.then(res => {
			if(res.status === CODES.notFound){
				window.location = '/';
			}else{
				return res.json();
			}
		})
		.then(res => {
			if( res.code === CODES.accessDenied ){
				//No cerramos sesión, sería mejor mostrar un mensaje de error
				console.error("You are not allowed to do this action");
				alert("No tienes permiso para acceder a esta sección");
				window.location = '/';
			}else if( res.code === CODES.authorizationRequired ){
				//Borrar token
				Auth.deleteToken();
				window.location = '/login';
			}else if(res.code === CODES.notFound){
				window.location = '/';
			}
			return res;
		})
	},
	getAuth(url){
		const token = Auth.getToken();
		return Request.get(url, {
			'Authorization': 'Basic '+btoa('token:'+token),
		})
	},

	post(url, body, headers = {}, isJSON = true){
		if(isJSON){
			if( !headers['Content-Type']){
				headers['Content-Type'] = 'application/json';
			}
			body = JSON.stringify(body)
		}
		return fetch(url,{
			method: 'POST',
			headers: headers,
			body: body,
		})
		.then(res => {
			if(res.status === CODES.notFound){
				window.location = '/404';
			}else{
				return res.json();
			}
		})
		.then(res => {
			if(res.code === CODES.notFound){
				window.location = '/404';
			}
			return res;
		})
	},
	postAuth(url, body, headers = {}, isJSON = true){
		const token = Auth.getToken();
		headers['Authorization'] = 'Basic '+btoa('token:'+token);
		return Request.post(url, body, headers, isJSON)
	},
	postAuthFile(url, body, headers = {}){
		return Request.postAuth(url, body, headers, false);
	},


	logout(){
		return Request.getAuth('/api/account/logout').then( res => {
			console.log("logout", res);
			if(res.code === CODES.success){
				console.log("logout ok");
				Auth.deleteToken();
			}
			return res;
		});
	},

	delete(url, body){
		const token = Auth.getToken();
		return fetch(url,{
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Basic '+btoa('token:'+token),
			},
			body: JSON.stringify(body),
		})
		.then(res => {return res.json()})
		.then(res => {
			if( res.code === CODES.accessDenied ){
				//No cerramos sesión, sería mejor mostrar un mensaje de error
				console.error("You are not allowed to do this action");
			}else if( res.code === CODES.authorizationRequired ){
				//Borrar token
				Auth.deleteToken();
				window.location = '/login';
			}
			return res;
		})
	},

};

export default Request;


export const CODES = {
	success : 200,
	noContent: 204,
	redirect : 301,
	authorizationRequired : 401,
	accessDenied : 403,
	notFound : 404,
	serverError : 500,
	formError : 600,
	fieldError : 601,
	dependencyError : 602,
}


export const PERMISSIONS = {
	ADMINISTRATOR: 0,
	GESTOR_TIENDA: 5,
	can: (user, role)=>{
		return role >= user.role;
	},
	canAdministrator: (user)=>{
		return PERMISSIONS.can(user, PERMISSIONS.ADMINISTRATOR);
	},
	canGestorTienda: (user)=>{
		return PERMISSIONS.can(user, PERMISSIONS.GESTOR_TIENDA);
	},
}


export function isAdministrator(role){
	if(role === PERMISSIONS.ADMINISTRATOR){
		return true;
	}else{
		return false;
	}
}


export function isGestorTienda(role){
	if(role === PERMISSIONS.GESTOR_TIENDA){
		return true;
	}else{
		return false;
	}
}