import React, {Component} from "react";


let countPopup = 0;
class confirmPopup extends Component {

		
	constructor(props) {
		super(props);
		this.id = 'confirm-popup-'+ countPopup++;
		this.state = {
		}

		this.closePopup = this.closePopup.bind(this);
		this.handleHasDependencies = this.handleHasDependencies.bind(this);
	}

	componentDidMount(){
		
	}
	
	closePopup(){
		// document.getElementById(this.id).classList.remove("active");
		this.props.cancel();
	}

	handleHasDependencies(event){
		let id = document.querySelector('#select-'+this.id).value;
		this.props.success(event,id);
	}

	render(){
		if(this.props.visible){

			return(
				<div className="popup-container confirm-popup-container active" type={this.state.type} id={this.id} onClick={this.closePopup}>
					<div className="popup-content create-popup-content">
						<div className='confirm-text-container'>
							<p className="font-p">{this.props.description}</p>
						</div>
						<div className='confirm-buttons-container'>
							<p className="button font-p" onClick={this.props.success}>Confirmar</p>
							<p className="button font-p" onClick={this.closePopup}>Cancelar</p>
						</div>
						
					</div>
				</div>
			)

		}else{
			return null;
		}
	}
}

export default confirmPopup;


