import React, {Component} from "react";
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import Request, {CODES} from '../../utils/Request';



class InputSerialNumber extends Component {
	constructor(props){
		super(props);

		this.state = {
			status : 'success',
			inputMessage : '',
			inputValue: '',
			value: this.props.form.getValue(this.props.name) || this.props.defaultValue || [] ,
			options: false,
		}

		this.validateInput = this.validateInput.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
		this.loadOptions = this.loadOptions.bind(this);
	}

	componentDidMount(){
		let value = this.props.form.getValue(this.props.name);
		if((value == undefined || value.length == 0) && this.props.defaultValue !== undefined){
			this.props.form.setValue(this.props.name, this.props.defaultValue);
			value = this.props.defaultValue;
		}
		this.validateInput(undefined);

		Request.getAuth('/api/products/searcher-serial/?sold=0').then( res => {
			if(res.code === CODES.success){
				let options = [];
				for(let product of res.products){
					options.push({
						value: product.id,
						label: product.serial_number,
						sku: product.sku,
					});
				}
				this.setState({options: options}, ()=>this.validateInput(value));
			}
		});
	}

	componentWillUnmount(){
		this.props.form.setValidate(this.props.name, true);
	}

	validateInput(value){
		let valid = true;
		if(this.props.required && ( value === undefined || value.length == 0)){
			valid = false;
			this.setState({
				status: "error",
				inputMessage: this.props.name + ' is required',
			});
		}else{
			this.setState({
				status: "success",
				inputMessage: '',
			})
		}

		this.props.form.setValidate(this.props.name, valid);
	}

	
	// if(!this.state.orderProducts[this.state.values.serials[i].sku]){
	// 	include = window.confirm('El producto ' + this.state.values.serials[i].sku + ' no está en el pedido. ¿Quieres añadirlo?');
	// }
	// if(include){

	handleOnChange(value){
		if(!value){
			value = [];
		}
		this.validateInput(value);
		this.props.form.setValue(this.props.name, value);
	}

	loadOptions(inputValue,  callback){
		// if(inputValue.length > 2){
			Request.getAuth('/api/products/searcher-serial/'+inputValue+'?sold=0').then( res => {
				if(res.code === CODES.success){
					let options = [];
					let label = this.state.label;
					for(let product of res.products){
						options.push({
							value: product.id,
							label: product.serial_number,
							sku: product.sku,
						});
						if(!label && this.state.value == product.id){
							label = product.serial_number;
						}
					}
					callback(options);
					if(label != this.state.label){
						this.setState({currentLabel:label});
					}
				}
			});
		// }else{
		// 	callback([]);
		// }
	};

	render(){

		if(!this.state.options) return null;
		let statusClass = (this.state.status === "error") ? "input-error" : "";
		let readOnlyClass = (this.props.readOnly) ? " read-only" : "";
		let value = this.props.form.getValue(this.props.name) || this.props.defaultValue || [] ;

		let input = 		
		    <label className={"font-p input-container "+statusClass+readOnlyClass} data-message={this.state.inputMessage}>
		    	{(this.props.label !== undefined)? 
		    		(<span className="label-text">{this.props.label + " ("+value.length+"): "}</span>)
		    		:
		    		(null)
		    	}
		    	{/*<AsyncSelect cacheOptions defaultOptions loadOptions={this.loadOptions}
		    		// noOptionsMessage={input => ((input.inputValue.length > 2)?"No encontrado":"Escribe al menos 3 caracteres")}
					className="input-options-container select-custom-container"
					classNamePrefix="select-custom"
					isMulti
					onChange={this.handleOnChange}
					value = {this.props.form.getValue(this.props.name)}
		    	/>*/}
		    	<Select 
		    		options={this.state.options}
		    		// noOptionsMessage={input => ((input.inputValue.length > 2)?"No encontrado":"Escribe al menos 3 caracteres")}
					className="input-options-container select-custom-container"
					classNamePrefix="select-custom"
					isMulti
					onChange={this.handleOnChange}
					value = {value}
		    	/>
	        </label>
		return input;
	}
}

export default InputSerialNumber;