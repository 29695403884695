import React, {Component} from "react";



class InputDate extends Component {
	constructor(props){
		super(props);

		this.state = {
			status : 'success',
			inputMessage : '',
			value: this.props.form.getValue(this.props.name),
		}


		this.validateInput = this.validateInput.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
		this.getFormattedDate = this.getFormattedDate.bind(this);
		
	}

	componentDidMount(){
		let value = this.props.form.getValue(this.props.name);
		if((value == "" || value == undefined) && this.props.defaultValue !== undefined){
			value = this.getFormattedDate(this.props.defaultValue);
			this.props.form.setValue(this.props.name, value);
		}
		this.validateInput(value);
	}

	validateInput(value){
		let valid = true;
		if(this.props.required && ( value === undefined || value === "")){
			valid = false;
			this.setState({
				status: "error",
				inputMessage: this.props.name + ' is required',
			});
		}else{
			this.setState({
				status: "success",
				inputMessage: '',
			})
		}

		this.props.form.setValidate(this.props.name, valid);
	}

	handleOnChange(event){
		this.validateInput(event.target.value);
		this.props.form.setValue(this.props.name, event.target.value);
	}

	getFormattedDate(date){
		let d = new Date(String(date));

		let curr_date = ("0" + d.getDate()).slice(-2);
		let curr_month = ("0" + (d.getMonth() + 1)).slice(-2); //Months are zero based
		let curr_year = d.getFullYear();
		return (curr_year + "-" + curr_month + "-" + curr_date);
	}

	render(){
		let dateValue = this.props.form.getValue(this.props.name);
		if(dateValue){
			dateValue = this.getFormattedDate(dateValue);
		}
		let statusClass = (this.state.status === "error") ? "input-error" : "";
		let input = 		
		    <label className={"font-p input-container "+statusClass} data-message={this.state.inputMessage}>
	        	<span className="label-text">{(this.props.label !== undefined) ? this.props.label + ": " : null} </span>
	        	<input type="date" name={this.props.name} value={dateValue} onChange={this.handleOnChange} readOnly={this.props.readOnly}/>
	        </label>
		return input;
	}
}

export default InputDate;