import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { getFromStorage, setInStorage } from './storage';


export const Auth = {
	isAuthenticated() {
		if(this.getToken()){
			return true;
		}else{
			return false;
		}
	},

	deleteToken(){
		this.setToken(false);
	}, 

	getToken(){
		const obj = getFromStorage('stk_credentials');
		if(obj && obj.token){
			return obj.token;
		}else{
			return false;
		}
	},

	getUserID(){
		const obj = getFromStorage('stk_credentials');
		if(obj && obj.userID){
			return obj.userID;
		}else{
			return false;
		}
	},

	setToken(token, user){
		// setInStorage('stk_credentials', {token: token, userID: user.email.replace(/[^0-9a-z]/gi, '')});
		setInStorage('stk_credentials', {token: token});
	}
};


export const PrivateRoute = ({ component: Component, ...rest }) => {
	return <Route
		{...rest}
		render = {props => 
			Auth.isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: "/login",
						state: { from: props.location }
					}}
				/>
			)
		}
	/>
};
