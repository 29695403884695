export function translateStatus(status){
    switch(status){
        case 'on-hold':
            return 'En espera';
        case 'processing':
            return 'Procesando';
        case 'gls-sent':
            return 'GLS Enviado';
        default:
            return status;
    }
}


export function getFormattedDate(date, type){
    if(type === undefined){
        type = "full";
    }
    let formtaDate;

    if(type=== "date"){
        formtaDate = new Date(date).toLocaleDateString();
    }else if(type=== "time"){
        formtaDate = new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }else{
        formtaDate = new Date(date).toLocaleString([],{
            // weekday: "long",
            year: "numeric",
            month: "2-digit",
            day: "numeric",
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    return formtaDate;
}


Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
};