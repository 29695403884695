import React, {Component} from "react";



class InputTrueFalse extends Component {
	constructor(props){
		super(props);
		this.validateInput(props.value);

		this.validateInput = this.validateInput.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
	}


	validateInput(value){
		let valid = true;
		if(this.props.required && ( value === undefined || value === false)){
			valid = false;			
		}
		this.props.form.setValidate(this.props.name, valid);
	}

	componentDidMount(){
		let value = this.props.form.getValue(this.props.name);
		if((value == "" || value == undefined) && this.props.defaultValue !== undefined){
			this.props.form.setValue(this.props.name, this.props.defaultValue);
			value = this.props.defaultValue;
		}
		this.validateInput(value);
	}

	handleOnChange(event){
		this.validateInput(event.target.checked);
		this.props.form.setValue(this.props.name, event.target.checked);
	}

	render(){
		let value = this.props.form.getValue(this.props.name);
		let input = 		
		    <label className="input-container checkbox-container font-p">      
	          <input type="checkbox" checked={value} onChange={this.handleOnChange} />
			  <span className="label-text">{(this.props.label !== undefined) ? this.props.label : null}</span>	          
	        </label>
		return input;
	}
}

export default InputTrueFalse;