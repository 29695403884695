import React, {Component} from "react";
import {NavLink} from 'react-router-dom';

import UserContext from '../../utils/UserContext';
import {PERMISSIONS} from '../../utils/Request';

class MenuLateral extends Component {
	
	constructor(props){
		super(props)
	}
	
	componentDidMount(){
		
	}

	render(){
		return(
	        <div className="menu-lateral ">
	        	<UserContext.Consumer>
					{user => {
						return (
			        	<div className="menu-container">
			        		<NavLink to="/" className="menu-item">
			        			<p className="font-p">Inicio</p>
			        		</NavLink>
			        		<NavLink to="/productos" className="menu-item">
			        			<p className="font-p">Productos</p>
			        		</NavLink>
			        		<NavLink to="/stock" className="menu-item">
			        			<p className="font-p">Stock</p>
			        		</NavLink>
			        		<NavLink to="/pedidos" className="menu-item">
			        			<p className="font-p">Pedidos pendientes</p>
			        		</NavLink>
			        		<NavLink to="/pedidos-registrados" className="menu-item">
			        			<p className="font-p">Pedidos registrados</p>
			        		</NavLink>
			        		<NavLink to="/entradas" className="menu-item">
			        			<p className="font-p">Entradas</p>
			        		</NavLink>
			        		<NavLink to="/productos-sn" className="menu-item">
			        			<p className="font-p">Productos <br/> con SN</p>
			        		</NavLink>
			        		{(PERMISSIONS.canAdministrator(user))?(
				        		<NavLink to="/usuarios" className="menu-item">
				        			<p className="font-p">Usuarios</p>
				        		</NavLink>
			        		):null}
			        	</div>
					)}}
				</UserContext.Consumer>
	        </div>
		);
	}
}


export default MenuLateral;
