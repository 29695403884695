import React, {useContext} from "react";

import {NavLink, Link} from "react-router-dom";
import pageList from './pageList';
import Request, {CODES} from '../../utils/Request';

import UserContext from '../../utils/UserContext';

import {RMA} from '../../utils/svgs';


class pageListOrderEntry extends pageList {
	
	constructor(props){
		super(props)

		this.entryEncoded = this.props.match.params.entryEncoded;
		let entryDecoded = JSON.parse(atob(this.entryEncoded));
		this.date = entryDecoded.date;
		this.invoice = entryDecoded.invoice;
		this.provider = entryDecoded.provider;

		this.responseField = 'products';
		this.apiName = 'orders-entry/'+this.entryEncoded;
		this.listSlug = 'orders';
		this.singleItem = 'pedido'; //Name of a single list item
		this.listTitle = 'Ficha de la entrada: '+this.invoice;
		this.listSubTitle = `Proveedor: ${this.provider}
		Fecha: ${this.date}`;

		this.hasRowOptions = false;
		this.listWithoutLinks = true;
		// this.itemLinkSlug = 'item';

	}
	
	componentDidMount(){
		this.requestPages();
	}

	getTable(pages){
		let table = {};
		table.headers = [
			{
				slug: 'serial_number',
				name: 'Número Serie',
				class: 'item-big',
				canSearch: true,
			},
			{
				slug: 'sku',
				name: 'SKU',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'provider_bought',
				name: 'Proveedor',
				class: 'item-medium',
			},
			{
				slug: 'invoice_bought',
				name: 'Factura Compra',
				class: 'item-medium',
			},
			{
				slug: 'date_bought',
				name: 'Fecha Compra',
				class: 'item-medium',
			},
		];
		table.rows = [];
		for(let i = 0; i < pages.length; i++){
			table.rows.push(this.createRow(pages[i]));
		}
		return table;
	}

	createRow(page){
		return {
			item : page,
			id : page.id,
			serial_number : page.serial_number,
			sku : page.sku,
			date_bought : page.date_bought,
			provider_bought : page.provider_bought,
			invoice_bought : page.invoice_bought,
		};
	}

	renderAddNew(){
		return <Link to={'/add-stock/?invoice='+this.invoice+'&date='+this.date+'&provider='+this.provider} className="button button-default font-p font-white font-center">Añadir más productos</Link>;
	}
}





export default pageListOrderEntry;




