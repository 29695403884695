import React, {Component} from "react";
import { Link } from "react-router-dom";
// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";
import Request, {CODES, PERMISSIONS} from '../../utils/Request';
import Form from "../../utils/Form";
import InputText from "../../utils/inputs/InputText";
import InputRadio from "../../utils/inputs/InputRadio";
import InputEmail from "../../utils/inputs/InputEmail";
import InputPassword from "../../utils/inputs/InputPassword";
import InputTrueFalse from "../../utils/inputs/InputTrueFalse";


class pageUser extends Form {
	
	constructor(props){
		super(props);
		this.formRef = React.createRef();
		
		this.from = null;

		this.roleOptions =  {}
		for(let key in PERMISSIONS){
			if(typeof(PERMISSIONS[key]) !== 'function'){
				this.roleOptions[PERMISSIONS[key]] = key.replace('_', ' ');
			}
		}
		
		
		if(this.props.location.state){
			this.from = this.props.location.state.from.pathname;
		}
		this.userId = this.props.match.params.userId;

		// this.handleInput = this.handleInput.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getFormMessage = this.getFormMessage.bind(this);
		this.isNewUser = this.isNewUser.bind(this);
		this.isStatusSuccess = this.isStatusSuccess.bind(this);
		
	}
	
	componentDidMount(){
		Request.getAuth('/api/users/'+this.userId).then( res => {
			if(res.code === CODES.success){
				this.setState({
					// currentUser:res.user,
					values: res.user,
					loaded: true,
				});
				
			}
		});

	}


	handleSubmit(event){
		event.preventDefault();
		if(this.isValidForm()){
			if(!this.sendingForm){
				this.sendingForm = true;
				Request.postAuth('/api/users/'+this.userId, this.state.values)
					.then(json => {
					 	this.sendingForm = false;
						if( json.code === CODES.success){
						 	this.setState({
						 		formMessage: {
									status: 'success',
									text: 'Usuario actualizado',
								}
						 	});
							this.props.history.push('/usuarios/'+json.user.id);

						}else{
							this.setState({
						 		formMessage: {
									status: 'error',
									text: 'Error',
								}
						 	});
						}
				});
			}
		}else{
			console.error("Input error: this.isValidForm() = false");
			// this.setState({
			// 	formMessage: {
			// 		text: '',
			// 	},
			// })
		}
	}

	isNewUser(){
		if(this.userId === "new-temp"){
			return true;
		}
		return false;
	}


	isStatusSuccess(){
		if(this.state.formMessage && this.state.formMessage.status !== 'success'){
			return false;
		}
		return true;
	}

	render(){
		if(!this.state.loaded) return null;

		let buttonText = "Actualizar usuario";
		let title = "Actualizar usuario";
		
		if(this.isNewUser()){
			title = buttonText = "Crear usuario";
		}
		
		let statusClass = '';
		if(!this.isStatusSuccess()){
			statusClass = 'form-error';
		}

		return(
			<section className="page page-user">
				<div className="page-main-container">
					<div className="page-title">
						<div className="container-left">
							<p className="font-h2">{title}</p>
						</div>
					</div>
					<form onSubmit={this.handleSubmit} className={'bb-form ' + statusClass} ref={this.formRef}>
						
						<InputText label="Nombre" name="name" form={this} required/>
						<InputEmail label="Email" name="email" form={this} required/>
						<InputRadio label="Role" name="role" form={this} values={this.roleOptions} required />
						<InputPassword label="Password" name="password" form={this}/>

						<div className="button-container font-center">
							<input className="button button-blue font-p" type="submit" value={buttonText}/>
						</div>	
						<p className="form-message font-p2">Mensaje: {this.state.formMessage.text}&nbsp;</p>


					</form>
				</div>


			</section>
		);
	}
}





export default pageUser;




