import React from "react";
import CmsApp from "./lms-cms/CmsApp";
import pageLogin from "./containers/pageLogin";
import page404 from "./containers/page404";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./utils/Auth";

import './css/style.css';


const App = () => (
  <Switch>
    <Route path="/reset-password" component={pageLogin} />
    <Route path="/login" component={pageLogin} />
    <Route path="/404" component={page404} />
    <PrivateRoute path="/" component={CmsApp} />
  </Switch>
);

export default App;