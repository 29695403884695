import React from "react";
import {NavLink} from "react-router-dom";
import pageList from './pageList';
import Request, {CODES} from '../../utils/Request';
import {Duplicate} from '../../utils/svgs';

// import GeneralAnimations from '../utils/generalAnimations';
// import {TweenMax, Power2} from "gsap/TweenMax";


class pageListProductsSN extends pageList {
	
	constructor(props){
		super(props)
		this.apiName = 'products-sn';
		this.listSlug = 'productos';
		this.singleItem = 'producto'; //Name of a single list item
		this.listTitle = 'Productos con SN';
		this.responseField = 'products';

		this.hasRowOptions = false;
		this.itemLinkSlug = 'item';
	}
	
	componentDidMount(){
		this.requestPages();
	}

	getTable(pages){
		let table = {};
		table.headers = [
			{
				slug: 'sku',
				name: 'SKU',
				class: 'item-medium',
				canSearch: true,
			},
			{
				slug: 'serial_number',
				name: 'Número Serie',
				class: 'item-big',
				canSearch: true,
			},
			{
				slug: 'date_bought',
				name: 'Fecha Compra',
				class: 'item-medium',
			},
			{
				slug: 'provider_bought',
				name: 'Proveedor',
				class: 'item-medium',
			},
			{
				slug: 'invoice_bought',
				name: 'Factura Compra',
				class: 'item-medium',
			},
			{
				slug: 'date_sold',
				name: 'Fecha Venta',
				class: 'item-medium',
			},
			{
				slug: 'order_sold',
				name: '# Pedido',
				class: 'item-medium',
			},
			{
				slug: 'comment',
				name: 'Comentario',
				class: 'item-medium',
			},
		];
		table.rows = [];
		for(let i = 0; i < pages.length; i++){
			table.rows.push(this.createRow(pages[i]));
		}
		return table;
	}

	createRow(page){
		return {
			item : page,
			sku : page.sku,
			serial_number : page.serial_number,
			date_bought : page.date_bought,
			provider_bought : page.provider_bought,
			invoice_bought : page.invoice_bought,
			date_sold : page.date_sold,
			order_sold : page.order_sold,
			comment : page.comment,
		};
	}


	renderAddNew(){}

	
}





export default pageListProductsSN;




